import React from 'react'

type WorkType = {
  heading: string
  img: string[]
  link?: Link[]
  bgColor: string
   grid:string
}



type Link = {
  logo: string
  link: string
  linkName: string
 
}

const Work = ({heading, img, link, bgColor, grid}:WorkType) => {
  return (
    <div className={`${bgColor}`}>
      <div className='max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-20'>
        <div className=''>
          <h1 className='font-montserrat font-bold text-3xl text-white mb-11'>{heading}</h1>
          <div className={`grid md:grid-cols-2 ${grid} mb-5 lg:mb-11 gap-4 lg:gap-10`}>
            {img.map((item) => (
              <div className=''>
                <img className='w-full h-full' src={item} alt='' />
                </div>
            ))}

          </div>
          <div className='flex flex-col lg:flex-row flex-wrap text-white font-gtAmerica justify-between gap-4 lg:gap-10'>
            {link && link.map((item, index) => (
              <div key={index} className='flex gap-2 items-center'>
                <img src={item.logo} />
                <a className='' href={item.link} target='_blank'>{ item.linkName}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work