import React, { createContext, useState, useEffect, ReactNode } from "react";
import { fetchBlogs } from "../service/service";
import { BlogType } from "../types/types";
import { blogData } from "../Resources/BlogData";

export type UserOrNull = {
  id: string;
  email: string;
} | null;

export interface AuthContextType {
  isAuthenticated: boolean;
  blogs: BlogType[];
  setBlogs: React.Dispatch<React.SetStateAction<BlogType[]>>;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  user: UserOrNull;
  setUser: React.Dispatch<React.SetStateAction<UserOrNull>>;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  
}




const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserOrNull>(null);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState<BlogType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 9;

  useEffect(() => {
    const getBlogs = async () => {
      try {
        // const data = await fetchBlogs(currentPage, limit);
        // if (data.status === false) {
        //   toast.error(data.response?.data.message);
        // } else {
        //   setBlogs(data.response?.data.blogs as BlogType[]);
        //   setTotalPages(data.response?.data.totalPages as number);
        // }
        setBlogs(blogData);
        setTotalPages(2)
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    getBlogs();
  }, [currentPage]);


  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };



  

  return (
    <AuthContext.Provider
      value={{
        handlePageChange,
        blogs,
        currentPage,
        totalPages,
        setBlogs,
        isAuthenticated,
      
        loading,
        user,
        setUser,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
