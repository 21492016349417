import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  socialmedia1,
  socialmedia2,
  socialmedia3,
  socialmedia4,
  socialmedia5,
  socialmedia6,
} from "../../../assets/images/index";
import PrimaryButton from "../../common/PrimaryButton";
import arrow from "../../../assets/images/arrow.svg";
import ContactPopup from "../../common/ContactPopup";

type MediaProps = {
  img: string;
};

const medias: MediaProps[] = [
  {
    img: socialmedia1,
  },
  {
    img: socialmedia2,
  },
  {
    img: socialmedia3,
  },
  {
    img: socialmedia4,
  },
  {
    img: socialmedia5,
  },
  {
    img: socialmedia6,
  },
];
const SocialMedia = () => {
  const [media, setMedia] = useState<MediaProps[]>(medias);
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="w-full flex flex-col justify-center mx-auto py-8 sm:py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl">
          Social Media
        </h2>
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-8 lg:mb-14">
          {media.map((item, index) => (
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              key={index}
              className="w-full"
            >
              <img src={item.img} alt="" className="w-full h-full" />
            </motion.div>
          ))}
        </div>
        <PrimaryButton
          className="relative z-10 bg-primary self-center"
          icon={<img src={arrow} alt="arrow" />}
          onClick={() => setIsOpen(!modalIsOpen)}
        >
          Start a project with us
        </PrimaryButton>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup isOpen={modalIsOpen} setIsOpen={setIsOpen} service="marketing" />
        </div>
      )}
    </>
  );
};

export default SocialMedia;
