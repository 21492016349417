import axios from "axios";

let url = "https://admin.techqilla.com/api/";

if (process.env.NODE_ENV === "development") {
  url = "https://admin.techqilla.com/api/";
}

const axiosClient = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const sendMailClient = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + process.env.REACT_APP_AUTH_API_TOKEN,
  },
});

export { sendMailClient, axiosClient };
