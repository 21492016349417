import React from 'react'
import case1 from '../../assets/images/softsidelogo.svg'
import case2 from "../../assets/images/aeslogo.svg"
import case3 from "../../assets/images/tanksandcoverslogo.svg"
import case4 from "../../assets/images/ggplogo.svg"
import PrimaryButton from '../common/PrimaryButton'
import arrow from "../../assets/images/arrowsecondary.svg";

import { Link } from 'react-router-dom'
import Data from './Data'

type CaseType = {
  img: string
  des: string
  path: string
}

const cases: CaseType[] = [
  {
    img: case1,
    des: "Explore our detailed case study on Softside, where we showcase how our tailored marketing strategies helped them overcome challenges, increase brand awareness, and achieve impressive results.",
    path: "/case-study/softside",
  },
  {
    img: case2,
    des: "Discover how Techqilla transformed Alexander Executive Search Limited with strategic insights and innovative solutions, driving exceptional talent acquisition.",
    path: "/case-study/aes",
  },
  {
    img: case3,
    des: "Uncover how Techqilla helped TanksandCovers dominate its niche with custom marketing strategies, boosting brand visibility, enhancing engagement, and driving remarkable business growth.",
    path: "/case-study/tanksandcover",
  },
  {
    img: case4,
    des: "Uncover how Techqilla helped TanksandCovers dominate its niche with custom marketing strategies, boosting brand visibility, enhancing engagement, and driving remarkable business growth.",
    path: "/case-study/ggp"
  }
];

const Cases = () => {
  return (
    <>
      <div className="px-5 lg:px-20 max-w-screen-2xl mx-auto py-8 lg:py-28">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-10">
          {cases.map((item, index) => (
            <div
              className="py-12 px-10 bg-secondary rounded-[50px] flex flex-col justify-between"
              key={index}
            >
              <img src={item.img} alt="" className="h-28 mb-10" />
              <p className="text-white mb-10">{item.des}</p>
              <Link to={item.path}>
                <PrimaryButton
                  className=" relative !bg-white !text-secondary z-10"
                  icon={<img src={arrow} />}
                >
                  View CaseStudy
                </PrimaryButton>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Data />
    </>
  );
}

export default Cases