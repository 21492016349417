import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { FaArrowRightLong } from "react-icons/fa6";
import "swiper/css";
import "swiper/css/pagination";
import PrimaryButton from "../../common/PrimaryButton";
import arrow from "../../../assets/images/arrow.svg";
import ContactPopup from "../../common/ContactPopup";

type SliderProps = {
  heading: string;
  className?: string;
  buttonData?: {
    name: string;
    className: string;
  };
};
const Video = ({ heading, className, buttonData }: SliderProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const swiperRef = useRef<any>();
  return (
    <>
      <div className={`w-full py-6 px-4 sm:py-20 sm:px-10  ${className}`}>
        <div className="max-w-screen-2xl mx-auto">
          <h3
            className={`font-montserrat mx-auto max-w-screen-2xl  font-bold text-2xl sm:text-6xl mb-10 `}
          >
            {heading}
          </h3>
          <div
            className="flex justify-end mb-5 lg:mr-10"
          >
            <div className="flex gap-2 relative z-10">
              <div className="swiper-button-prev p-4 bg-primary hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-white">
                <FaArrowRightLong className="text-white rotate-180" />
              </div>

              <div className="swiper-button-next p-4 bg-primary hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-white">
                <FaArrowRightLong className="text-white" />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-screen-2xl w-full flex justify-center items-center">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              className="flex justify-center items-center "
              pagination={{
                type: "progressbar",
                el: ".pagination-progress",
              }}
              spaceBetween={10}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                },
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop
              onSwiper={(swiper: any) => {
                swiperRef.current = swiper;
              }}
            >
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/JApjHZYkokw"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/5co-KRZdJIw"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/bKi29wOcmwM"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/ug74eVX3dtg"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/TTerRu8lRfM"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
              <SwiperSlide className="!flex justify-center">
                <iframe
                  src="https://www.youtube.com/embed/DN6rVqaPy_8"
                  title="YouTube Short"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded-lg shadow-lg h-[400px]"
                ></iframe>
              </SwiperSlide>
            </Swiper>
          </div>
          {buttonData && (
            <div className="flex justify-center items-center mt-12 relative z-10">
              <PrimaryButton
                className={buttonData.className}
                icon={<img src={arrow} alt="arrow" />}
                onClick={() => setIsOpen(!modalIsOpen)}
              >
                {buttonData.name}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="design"
          />
        </div>
      )}
    </>
  );
};

export default Video;
