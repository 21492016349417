import {
  homeproject1,
  homeproject2,
  homeproject3,
  homeproject4,
  homeproject5,
  homeproject7,
} from "../../../assets/images/index";
import DesignHeroSection from "./DesignHeroSection";
import LogoDesing from "./LogoDesing";
import Broucher from "./Broucher";
import Slider from "../../common/Slider";
import Label from "./Label";
import BusinessCard from "./BusinessCard";
import Branding from "./Branding";
import Video from "./Video";

const Design = () => {
  const images = [
    {
      name: homeproject1,
      link: "https://www.softsidebyhunt.com/",
    },
    {
      name: homeproject2,
      link: "https://lonestarscent.com/",
    },
    {
      name: homeproject3,
      link: "https://powersquared.tech/",
    },
    {
      name: homeproject4,
      link: "https://www.aesrecruitment.com/",
    },
    {
      name: homeproject5,
      link: "https://www.tanksandcovers.com/",
    },
    {
      name: homeproject7,
      link: "https://psycheethos.com/",
    },
  ]
  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <DesignHeroSection />
        {/* <DesignServices /> */}
        <LogoDesing />
        <Broucher />
      </div>
      <Slider
        id='ui-ux-design'
        className="relative bg-primary text-white z-10"
        heading="UI/UX Design"
        items={images}
        buttonData={{
          name: "Start a Project with us",
          className: "border border-white bg-primary !text-white border border-white ",
          
        }}
      />
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <Label />
        <BusinessCard />
      </div>
      <Video
        className="bg-primary text-white"
        heading="Video Editing"

        buttonData={{
          name: "Start a projects with us",
          className: " border border-white bg-primary",
        }}
      />
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <Branding />
      </div>
    </>
  );
};

export default Design;
