import React from "react";
import img1 from "../../assets/images/Case12.webp";
import img2 from "../../assets/images/Case13.webp";
import { motion } from "framer-motion";

const CaseHeroSection = () => {
  return (
    <>
      <section className="px-4 w-full sm:px-6 lg:px-8 py-6 sm:py-20 border-b-2 border-black xl:px-20">
        <div className="hidden sm:block">
          <div className="flex items-end justify-between mb-10">
            <motion.h2
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="text-6xl font-extrabold font-montserrat text-gray-900 mb-8"
            >
              Case Studies
            </motion.h2>
            <div className="flex gap-4 justify-center">
              <motion.div
                initial={{ y: -200, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay:0.5}}
                className="border-4 border-primary rounded-full object-cover "
              >
                <img
                  src={img1}
                  alt="case"
                  className="object-cover h-full rounded-full"
                />
              </motion.div>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1 }}
                className="flex h-40 w-40 border !rounded-full"
              >
                <div className="bg-secondary w-1/2 rounded-l-full"></div>
                <div className="bg-primary w-1/2 rounded-r-full "></div>
              </motion.div>
            </div>
          </div>
          <div className="sm:flex items-center justify-between gap-4">
            <motion.div
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="flex items-center gap-2"
            >
              <div className="rounded-full bg-primary text-white font-bold font-montserrat text-3xl size-24 flex justify-center items-center">
                100+
              </div>
              <p className="text-xl opacity-50 text-black font-montserrat ">
                Website Completed
              </p>
            </motion.div>
            <div className=" flex flex-col-reverse lg:flex-row gap-4 items-center">
              <motion.p
                initial={{ x: -200, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay:1 }}
                className=" w-[500px] lg:w-[600px] font-montserrat text-lg font-medium"
              >
                Explore a selection of our crafted work combining unique designs
                and rich technology. We always build from scratch, creating
                memorable brands engaging websites and digital products.
              </motion.p>
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 , delay: 1.5 }}
                className="border-4 border-primary rounded-full object-cover"
              >
                <img src={img2} alt="case" className="w-full" />
              </motion.div>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex flex-col gap-4">
          <motion.h2
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="text-4xl font-extrabold font-montserrat text-gray-900 mb-3"
          >
            Case Studies
          </motion.h2>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="flex items-center gap-2"
          >
            <div className="rounded-full bg-[#8d19da] text-white font-bold font-montserrat text-3xl px-4 py-6">
              20+
            </div>
            <p className="text-xl opacity-50 text-black font-montserrat ">
              Website Completed
            </p>
          </motion.div>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="border-4 border-primary rounded-full object-cover "
          >
            <img src={img1} alt="case" className="" />
          </motion.div>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="border-4 border-primary rounded-full object-cover"
          >
            <img src={img2} alt="case" className="w-full" />
          </motion.div>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="  font-montserrat text-lg font-medium"
          >
            Explore a selection of our crafted work combining unique designs and
            rich technology. We always build from scratch, creating memorable
            brands engaging websites and digital products.
          </motion.p>
        </div>
      </section>
    </>
  );
};

export default CaseHeroSection;
