import React, { useState } from "react";
import {
  branding1,
  branding2,
  branding3,
  branding4,
  branding5,
} from "../../../assets/images/index";
import arrow from "../../../assets/images/arrow.svg";
import PrimaryButton from "../../common/PrimaryButton";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const Branding = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        id="branding"
        className="w-full mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center"
      >
        <div className="text-center">
          <motion.h2
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl"
          >
            Branding
          </motion.h2>
          <motion.p
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="font-montserrat font-semibold text-xl "
          >
            From vision to reality—Techqilla’s branding services make your
            business unforgettable.
          </motion.p>
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-5 mb-8">
          <div className="grid  sm:grid-cols-2 gap-5 sm:p-6 mb-5 sm:mb-0 ">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="col-span-1 w-full"
            >
              <img src={branding1} alt="Image 1" className="rounded-lg" />
            </motion.div>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="col-span-1"
            >
              <img src={branding2} alt="Image 2" className="rounded-lg" />
            </motion.div>

            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 1 }}
              className="col-span-1"
            >
              <img src={branding4} alt="Image 4" className="rounded-lg" />
            </motion.div>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 1.5 }}
              className="col-span-1"
            >
              <img src={branding5} alt="Image 5" className="rounded-lg" />
            </motion.div>
          </div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2 }}
            className=" sm:block flex items-center"
          >
            <img
              src={branding3}
              alt="Image 3"
              className="rounded-lg sm:w-auto sm:h-full"
            />
          </motion.div>
        </div>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 2.5 }}
        >
          <PrimaryButton
            className="self-start bg-primary"
            icon={<img src={arrow} alt="arrow" />}
            onClick={() => setIsOpen(!modalIsOpen)}
          >
            Start a project with us
          </PrimaryButton>
        </motion.div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="design"
          />
        </div>
      )}
    </>
  );
};

export default Branding;
