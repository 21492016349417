import React, { useState } from "react";
import PrimaryButton from "../../common/PrimaryButton";
import arrow from "../../../assets/images/arrow.svg";
import img1 from "../../../assets/images/webhero1.webp";
import img2 from "../../../assets/images/webhero2.webp";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const WebDevelopmentHeroSection = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
        <div className="lg:w-[40%]">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
            className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
          >
            Web & App Development
          </motion.h1>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 0.5,
            }}
            className="font-montserrat font-semibold text-lg sm:text-xl mb-4 sm:mb-6 w-4/5"
          >
            Techqilla delivers seamless web & app development solutions to grow
            your business online.
          </motion.p>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 1,
            }}
          >
            <PrimaryButton
              icon={<img src={arrow} alt="arrow" />}
              className="relative bg-primary z-10"
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Talk About a project
            </PrimaryButton>
          </motion.div>
        </div>

        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="flex gap-2 lg:gap-8 lg:w-[60%] mb-4 lg:mb-0"
        >
          <img src={img1} alt="" className="w-[60%]" />
          <img src={img2} alt="" className="w-[40%]" />
        </motion.div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="web-app-dev"
          />
        </div>
      )}
    </>
  );
};

export default WebDevelopmentHeroSection;
