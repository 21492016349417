import React from "react";
import ourValues from "../../assets/images/ourValueImg.webp";
import { TbWorld } from "react-icons/tb";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FiYoutube } from "react-icons/fi";
import { RiCustomerService2Line } from "react-icons/ri";
import PrimaryButton from "../common/PrimaryButton";
import { motion } from "framer-motion";

const OurValues = () => {
  return (
    <div className="flex flex-col mx-auto items-center w-full px-4 sm:px-20 justify-center py-10 sm:py-20">
      <div className="flex flex-col items-center py-10  sm:px-20 bg-primary rounded-3xl w-full relative group">
        <motion.h2
          initial={{
            x: -100,
            opacity: 0,
          }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="text-3xl sm:text-5xl font-extrabold font-montserrat text-white text-center mb-8"
        >
          Our Values
        </motion.h2>
        <motion.p
          initial={{
            x: -100,
            opacity: 0,
          }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          className="text-lg sm:text-xl mb-6 text-center text-white"
        >
          We strive to create a culture that fosters a humble, creative and
          inspiring workspace.
        </motion.p>

        <motion.p
          initial={{
            x: -100,
            opacity: 0,
          }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          viewport={{ once: true }}
          className="text-lg sm:text-xl mb-10 text-white text-center"
        >
          We are passionate about helping small businesses and believe purpose,
          empathy, collaboration, initiative, and transparency are powerful
          tools to turn dreamers into doers. Our agency is designed as an end to
          support small business owners by giving them access to technology and
          creativity that will empower them and help them grow.
        </motion.p>

        <div className="size-[400px] bg-white p-14 rounded-full absolute bottom-6 left-6 bg-opacity-5 group-hover:scale-110 transition-all duration-300">
          <div className="size-full bg-white p-14 rounded-full bg-opacity-5 group-hover:scale-110 transition-all duration-300 delay-100">
            <div className="size-full bg-white rounded-full bg-opacity-5 group-hover:scale-110 transition-all duration-300 delay-300"></div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col sm:flex-row w-full gap-8 sm:items-center pt-10 sm:pt-20  justify-between">
        <div className="flex">
          <img src={ourValues} alt="our-values" className="object-cover" />
        </div>
        <div className="flex font-gtAmerica flex-col gap-2 sm:w-[60%]">
          <motion.h2
            className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl sm:leading-[60px]"
          >
            We boost <br /> your business
          </motion.h2>

          <hr className="w-full border border-gray-300" />
          <div className="flex justify-between items-center">
            <div className="flex items-center py-2 sm:px-2">
              <div className="p-3 bg-[#F5F5F5] rounded-full">
                <TbWorld className="w-8 h-8 text-secondary" />
              </div>
              <p className="ml-2 font-bold text-lg sm:text-xl">Website</p>
            </div>

            <div>
              <PrimaryButton className="!rounded-full !px-8 sm:!px-10 !bg-primary">
                Explore
              </PrimaryButton>
            </div>
          </div>
          <hr className="w-full border border-gray-300" />
          <div className="flex justify-between items-center">
            <div className="flex items-center py-2 sm:px-2">
              <div className="p-3 bg-[#F5F5F5] rounded-full">
                <IoPhonePortraitOutline className="w-8 h-8 text-secondary" />
              </div>
              <p className="ml-2 font-bold text-lg sm:text-xl">App</p>
            </div>

            <div>
              <PrimaryButton className="!rounded-full !px-8 sm:!px-10 !bg-primary">
                Explore
              </PrimaryButton>
            </div>
          </div>
          <hr className="w-full border border-gray-300" />
          <div className="flex justify-between items-center">
            <div className="flex items-center py-2 sm:px-2">
              <div className="p-3 bg-[#F5F5F5] rounded-full">
                <FiYoutube className="w-8 h-8 text-secondary" />
              </div>
              <p className="ml-2 font-bold text-lg sm:text-xl">Branding</p>
            </div>

            <div>
              <PrimaryButton className="!rounded-full !px-8 sm:!px-10 !bg-primary">
                Explore
              </PrimaryButton>
            </div>
          </div>
          <hr className="w-full border border-gray-300" />
          <div className="flex justify-between items-center">
            <div className="flex items-center py-2 sm:px-2">
              <div className="p-3 bg-[#F5F5F5] rounded-full">
                <RiCustomerService2Line className="w-8 h-8 text-secondary" />
              </div>
              <p className="ml-2 font-bold text-lg sm:text-xl">
                Virtual Assistant
              </p>
            </div>

            <div>
              <PrimaryButton className="!rounded-full !px-8 sm:!px-10 !bg-primary">
                Explore
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OurValues;
