import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loader from "./components/Loader";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function AppWithSnowfall() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000); // Adjust the time as needed
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />; // Show the loader while loading
  }

  return (
    <>
        <App />
    </>
  );
}

root.render(
  <React.StrictMode>
    <Router>
      <AppWithSnowfall />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
