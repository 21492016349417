import blogimg1 from "../assets/blogs/blog1.webp";
import people1 from "../assets/blogs/people1.webp";
import { BlogType } from "../types/types";

export const blogData: BlogType[] = [
  {
    slug: "",
    title: "Blog one",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
  {
    slug: "",
    title: "Blog 2",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
  {
    slug: "",
    title: "Blog 3",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
  {
    slug: "",
    title: "Blog 4",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
  {
    slug: "",
    title: "Blog 5",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
  {
    slug: "",
    title: "Blog 6",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vestibulum nec elit commodo purus ornare vitae sagittis. ",
    name: "Emily Devis",
    createdAt: "Mar 10, 2024",
    content: "",
    cover: {
      name: "",
      url: "",
    },
    techqilla_authors: [
      {
        name: "",
        image: {
          url: "",
        },
      },
    ],
    techqilla_categories: [
      {
        name: "",
      },
    ],
  },
];
export default blogData;
