import React from "react";
import MarketingHeroSection from "./MarketingHeroSection";
import GridComponent from "../../common/GridComponent";
import {
  marketingicon1,
  marketingicon2,
  marketingicon3,
  marketingicon4,
  marketingicon5,
  marketingicon6,
} from "../../../assets/images/index";
import SocialMedia from "./SocialMedia";
import Platform from "./Platform";
import { ServiceType } from "../../../types/types";

type ListType = {
  title: string;
  description: string;
  icon: string;
  serviceType: ServiceType;
};

const MarketingPage = () => {
  const griditem: ListType[] = [
    {
      icon: marketingicon1,
      title: "Social Media Marketing",
      description:
        "From content creation to targeted ads, Techqilla’s Social Media Marketing helps you stand out, engage your audience, and drive business results on every platform.",
      serviceType: "marketing",
    },
    {
      icon: marketingicon2,
      title: "Paid Marketing",
      description:
        "Drive more traffic and conversions with Techqilla’s Paid Marketing campaigns—strategically crafted for maximum impact and sustainable business growth.",
      serviceType: "marketing",
    },
    {
      icon: marketingicon3,
      title: "Search Engine Optimization",
      serviceType: "marketing",
      description:
        "Boost your website’s performance with Techqilla’s SEO services. We improve rankings, attract quality traffic, and increase conversions through proven optimization techniques.",
    },
    {
      icon: marketingicon4,
      title: "Amazon Marketing",
      serviceType: "marketing",
      description:
        "Get ahead on Amazon with Techqilla’s marketing strategies. We focus on SEO, ads, and optimization to boost sales, enhance visibility, and grow your business effortlessly.",
    },
    {
      icon: marketingicon5,
      title: "Influencer Marketing",
      serviceType: "marketing",
      description:
        "Harness the power of influencers with Techqilla. Our marketing strategies create authentic connections that drive brand awareness, engagement, and sales.",
    },
    {
      icon: marketingicon6,
      serviceType: "marketing",
      title: "Network/Referral Marketing",
      description:
        "Grow your business with Techqilla’s Network/Referral Marketing. We help you create referral systems that amplify your brand’s reach and bring in new customers effortlessly.",
    },
  ];
  return (
    <>
      <div className="flex flex-col overflow-hidden mx-auto max-w-screen-2xl items-center justify-center">
        <MarketingHeroSection />
      </div>
      <GridComponent heading="" list={griditem} />
      <Platform />
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <SocialMedia />
      </div>
    </>
  );
};

export default MarketingPage;
