import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { motion } from "framer-motion";
import linkdin from "../../assets/svgs/linkdin.svg";
import "swiper/css";
import "swiper/css/pagination";
import {
  diksha,
  vishal,
  huzaif,
  swapnil,
  prashant,
  vipul,
  piyush,
  jassi,
  deepti,
  ayush,
  aashtha,
  chandini,
  ranjan,
  teambg,
  yukta,
  ashish,
  kriti
} from "../../assets/images/index";

type Member = {
  name: string;
  position: string;
  image: string;
  description: string;
  link: string;
};

const members: Member[] = [
  {
    name: "Diksha Kuriyal",
    position: "Founder & CEO",
    image: diksha,
    description:
      "Our CEO, Diksha likes everything organized. Her room, her work, and her about section. Two years ago, she founded this creative agency to provide small businesses and corporations with delightful marketing services. Now? She helps businesses grow their audience and brand recognition through online modes. Along with prioritizing personal development she also focuses on developing others that makes her an effective leader. When she's not working, It's either caffeine or another set at the gym.",
    link: "https://in.linkedin.com/in/dikshakuriyal",
  },
  {
    name: "Vishal Kuriyal",
    position: "Creative head",
    image: vishal,
    description:
      "It’s tough to write about our creative head, more so because for him everything is about the visuals. Vishal has been working for 3 years and does graphic designing, illustration, UI design, and video editing. You could describe Vishal as a gym freak, a font aficionado, and an eternal seeker of inspiration for his designs. Vishal believes in collaboration while not forgetting about his own journey.",
    link: "https://www.linkedin.com/in/vishal-kuriyal-b1a373245/",
  },

  {
    name: "Swapnil Tripathi",
    position: "Vice President - Strategic Partnerships",
    image: swapnil,
    description:
      "With a diverse background in Mechanical Engineering and a former cricketer turned Vice President in Startegic Partnership, I bring a unique blend of analytical thinking and strategic creativity to the table. Armed with a degree in Mechanical Engineering, I possess a solid foundation in problem-solving and innovation. My cricketing journey taught me invaluable lessons in teamwork, resilience, and strategic thinking—skills that seamlessly translate into the business world. I began my career as a Brand Strategist, where I quickly found success in crafting impactful strategies that connect technology and business objectives. Today, as Vice President of Strategic Partnerships, I thrive at the intersection of technology, business, and strategy. My passion lies in bridging the gap between innovative engineering solutions and strategic business goals, leveraging both my engineering mindset and sporting experience to develop transformative solutions.",
    link: "https://www.linkedin.com/in/swapnil-techqilla/",
  },
  {
    name: "Huzaifa  Ahmed",
    position: "Vice President - Sales",
    image: huzaif,
    description:
      "I'm Huzaifa, and my life has been a constant journey, akin to a moving train, navigating through different places and experiences. From my early schooling in Jeddah, KSA, and Chennai, to pursuing a bachelor's degree in UP and a master's degree in Gurgaon, my education has been as diverse as my travels. My journey has been guided by a profound interest in science, particularly physics. However, driven by a desire to explore new horizons and embrace diverse opportunities, I chose to venture into commerce instead of following a conventional path. Today, I find myself at TechQilla, where I continue to explore the world, leveraging every opportunity to enhance my skills and knowledge. As a Vice president of sales at TechQilla, I am working hard to achieve targets and take the company to new heights with my sales and marketing skills. My background in commerce allows me to bring a unique perspective to our brand strategies, integrating technical insights with market dynamics to drive success.",
    link: "http://linkedin.com/in/huzaifa-ahmed-41a170259",
  },
  {
    name: "Prashant Semwal",
    position: "Digital Marketing Executive",
    image: prashant,
    description:
      "From an early age, I was intrigued by virtual gaming and technology. This passion naturally progressed into a deep interest in the digital world, ultimately leading me toward specializing in digital marketing. Over the past two years, I've been focused on enhancing online visibility for businesses through SEO and PPC. As a Brand Strategist, I leverage data-driven insights to develop effective digital marketing campaigns. I enjoy combining creativity with strategic thinking to drive business growth. And yes, One Piece is real.",
    link: "https://www.linkedin.com/in/prashantsemwal1209/",
  },
  {
    name: "Piyush Duggal",
    position: "Sr. Full Stack Developer",
    image: piyush,
    description:
      "Full-stack developer with two years of hands-on experience in developing and maintaining responsive web applications. Proficient in technologies like Javascript, TypeScript, Python, and modern frameworks such as ReactJS, Next.js, Node.js, Express.js, and MongoDB. Skilled in creating seamless and responsive user experiences and robust server-side logic.",
    link: "https://www.linkedin.com/in/piyush-duggal/",
  },
  {
    name: "Ayush Semwal",
    position: "Full Stack Developer",
    image: ayush,
    description:
      "I'm a dedicated Full Stack Developer with expertise in creating efficient, user-friendly web applications. I specialize in both front-end and back-end development, leveraging modern tools and frameworks to deliver innovative solutions. I enjoy solving complex problems and continuously expanding my skills to stay ahead in the ever-evolving tech landscape.",
    link: "https://www.linkedin.com/in/ayushsemwal/",
  },
  {
    name: "Aastha",
    position: "Human Resource Executive",
    image: aashtha,
    description:
      "I am a dedicated Human Resource Executive with a strong focus on recruitment, employee relations, and performance management. With a strategic approach, I align HR initiatives with organizational goals to drive business growth, enhance employee engagement, and cultivate a positive work environment. My commitment to professionalism and continuous improvement ensures the effective management of human capital while supporting both company objectives and employee well-being.w",
    link: "https://www.linkedin.com/in/aastha-dhull-53b9b424a/",
  },
  {
    name: "Chandni Saini",
    position: "Graphic & UI/UX Designer",
    image: chandini,
    description:
      "My name is Chandni Saini and I'm a Graphic & UI Designer who is passionate about creating designs that inspire and engage. With the years of experience in the industry, I've had the opportunity to work with clients from various sectors. My areas of expertise include Branding Design, Packaging Design, Social Media Design Website Designs and UI/UX Design. I' m always learning and experimenting with new design techniques to keep my work fresh and innovative.",
    link: "https://www.linkedin.com/in/chandni-saini-04896457/",
  },
  {
    name: "Vipul Pandey",
    position: "Head of Project Management Associate",
    image: vipul,
    description:
      "I began my career as a Business Development Associate at Byju's, where I honed my skills in strategic growth and client engagement. Recognizing an opportunity for advancement, I successfully navigated the interview process at Techqilla and transitioned into the role of Head of Project Management Associate. In this role, I thrive in a dynamic environment that fosters creativity and innovation, which fuels my passion for project management.",
    link: "http://linkedin.com/in/vipul-pandey-984552218",
  },
  {
    name: "Jaskeerat Singh",
    position: "Brand Strategist",
    image: jassi,
    description:
      "Hi, I'm Jaskeerat Singh Oberoi , a brand strategist who's always been obsessed with brands and their ability to make people feel seen, heard, and understood. Having a keen interes in the marketing field I pursued Bachelors in Business Economics. Being a passionate content creator what really drives me is finding innovative ways to tell stories, build connections, and drive engagement. I'm thrilled to bring my passion and expertise to TechQilla and contribute to the team's success.",
    link: "https://www.linkedin.com/in/jaskeeratsinghoberoi/",
  },
  {
    name: "Yukta Singh",
    position: "Content Specialist",
    image: yukta,
    description:
      "Hi, I’m a passionate content writer at Techqilla, where creativity meets strategy. With a knack for crafting compelling narratives, I specialize in delivering high-impact content tailored to your needs. Whether it’s persuasive copywriting, engaging scripts, SEO-driven web content, or thought-provoking blogs, I transform ideas into words that drive results. My role goes beyond just writing—I immerse myself in your brand's vision, ensuring every piece of content resonates with your target audience. At Techqilla, we don’t just create content; we create connections, and I’m here to help your brand tell its unique story. Let’s bring your vision to life!",
    link: "https://www.linkedin.com/in/yukta-singh-domain/",
  },
  {
    name: "Aayush Ranjan",
    position: "Graphic Designer & Video Editor",
    image: ranjan,
    description:
      " I am a passionate and skilled video editor with a keen eye for detail. I specialize in transforming raw footage into engaging and polished videos that tell a story. With experience in various video editing software, I am adept at crafting seamless edits, adding effects, and enhancing the visual and auditory elements to create compelling content. Whether working on short films, commercials, or social media videos, my goal is to bring creative ideas to life and deliver high-quality results.",
    link: "https://www.linkedin.com/in/aayush-ranjan-b71080238",
  },
  {
    name: "Deepti Verma",
    position: "Project Management Executive",
    image: deepti,
    description:
      "I am Deepti Verma, a Project Management Executive at TechQilla Private Limited. With an MBA in HR and Marketing and two years of experience in HR and Talent Acquisition, I specialize in project management and team collaboration. Passionate about nature, animals, chess, and Indian classical art, I was honored with a district-level award for my artwork. I am grateful for the ongoing support and mentorship at TechQilla, which enables me to contribute effectively to our goals.",
    link: "https://www.linkedin.com/in/deepti-verma-81bb41266",
  },
  {
    name: "Ashish Verma",
    position: "Legal Advisor",
    image: ashish,
    description:
      "Advocate Ashish Verma, a graduate of Campus Law Centre, Faculty of Law, University of Delhi (2023), is a dynamic legal professional practising in Delhi's District Courts and High Court. Ashish's legal journey is driven by a desire to make a difference. He approaches each case with an open mind and a commitment to continuous learning. His practice is characterised by a blend of intellectual curiosity, empathy, and dedication to justice. Ashish Verma's approach to law is characterized by a blend of intellectual curiosity, empathy, and a steadfast commitment to justice. Whether dealing with high-stakes litigation, offering counsel on sensitive matters, or contributing to innovative startups, he brings the same level of dedication and insight to every endeavour. As he continues to build his multifaceted career, Ashish remains focused on leveraging his skills and knowledge to navigate the complexities of the legal system while making a positive impact on society and the business world.",
    link: "https://www.linkedin.com/in/av00/",
  },
  {
    name: "Kriti Garg",
    position: "Business Development Executive",
    image: kriti,
    description:
     '"Empowering Businesses, One Opportunity at a Time" — my career has been all about creating meaningful growth. With a Master’s degree in Marketing and Finance from Chandigarh University and solid experience in sales, I’ve developed a keen understanding of what drives success. As a Business Development Executive at Techqilla Pvt. Ltd., I’m passionate about identifying new opportunities, building long-lasting client relationships, and driving impactful results through innovative solutions. Outside of work, I’m an avid reader of novels and enjoy playing badminton, which keeps me balanced and energized.',
    link: "http://www.linkedin.com/in/kriti-garg-625a1b235",
  },
];

const Creators = () => {
  const [activeMemberIndex, setActiveMemberIndex] = useState<number | null>(
    null,
  );

  const handleMemberClick = (index: number) => {
    const isMobile = window.innerWidth < 768; // Adjust breakpoint for mobile
    if (isMobile) {
      setActiveMemberIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle active state
    }
  };

  return (
    <div className="flex flex-col bg-white mx-auto items-center w-full px-4 sm:px-20 justify-center py-10 sm:py-20">
      <div className="flex flex-col w-full max-w-screen-2xl">
        <div className="flex flex-col sm:flex-row justify-between sm:mr-20 gap-4 sm:gap-0">
          <motion.h2 className="text-3xl font-extrabold font-montserrat mb-4 sm:mb-8 sm:text-5xl">
            Meet our team of Creators, <br /> designers and problem solvers
          </motion.h2>
          <div className="flex my-auto gap-2">
            <div className="swiper-button-prev p-4 bg-secondary  [&>*]:hover:text-black transition-custom rounded-full relative z-10 cursor-pointer">
              <FaArrowRightLong className="text-white rotate-180" />
            </div>
            <div className="swiper-button-next p-4 bg-secondary [&>*]:hover:text-black transition-custom rounded-full relative z-10 cursor-pointer">
              <FaArrowRightLong className="text-white" />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 4,
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            loop={true}
          >
            {members.map((member, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`relative flex h-full w-full items-end overflow-hidden rounded-2xl z-10 ${activeMemberIndex === index || window.innerWidth >= 768
                      ? "group"
                      : ""
                    }`}
                  onClick={() => handleMemberClick(index)}
                >
                  {/* Background Image */}
                  <img
                    src={teambg} alt="team background"
                    className={`w-full h-[85%] absolute bottom-0 transition-all duration-500 ${activeMemberIndex === index
                        ? "h-full"
                        : "group-hover:h-full"
                      }`}
                  />
                  {/* Foreground Image */}
                  <img
                    className={`w-full object-cover object-center h-full relative z-20 transition-all duration-500 ease-in-out ${activeMemberIndex === index
                        ? "opacity-0"
                        : "group-hover:opacity-0"
                      }`}
                    src={member.image} alt={member.name}
                  />
                  {/* Content */}
                  <div className="w-full sm:h-[85%] absolute bottom-0 text-white z-30 px-5 py-4 custom-gradient">
                    <div
                      className={`flex justify-between transition-all w-full duration-500 ${activeMemberIndex === index
                          ? "-translate-y-8"
                          : "translate-y-[300px] sm:translate-y-[280px] lg:translate-y-[230px] xl:translate-y-[250px]  group-hover:-translate-y-8"
                        }`}
                    >
                      <h2 className="font-bold font-montserrat text-2xl w-full">
                        {member.name}
                      </h2>
                      <div className="inset-0 size-10 flex justify-center items-center rounded-full bg-white opacity-0 group-hover:opacity-100 transition-all duration-500 cursor-pointer">
                        <a
                          href={member.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={linkdin} alt="Linkdin Background"className="w-full" />
                        </a>
                      </div>
                    </div>
                    <p
                      className={`font-gtAmerica  font-semibold transition-all duration-500 ${activeMemberIndex === index
                          ? "-translate-y-8"
                          : "translate-y-[300px] sm:translate-y-[280px] lg:translate-y-[230px] xl:translate-y-[250px]  group-hover:-translate-y-8"
                        }`}
                    >
                      {member.position}
                    </p>
                    <p
                      className={`opacity-0 transform transition-all duration-500 ease-in-out ${activeMemberIndex === index
                          ? "-translate-y-8 opacity-100"
                          : "translate-y-52 group-hover:-translate-y-8 group-hover:opacity-100"
                        } text-white font-gtAmerica text-lg overflow-y-auto  h-[300px]`}
                    >
                      {member.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Creators;
