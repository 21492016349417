import React from "react";
import { motion } from "framer-motion";
import hero from "../../assets/images/bloghero.webp";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import { useNavigate } from "react-router-dom";

const BlogHeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full mx-auto py-6 sm:py-20 pl-4 sm:pl-6 lg:pl-8 flex flex-col-reverse gap-10 lg:flex-row justify-between items-center border-b-2 border-black">
      <div className="lg:w-[60%]">
        <motion.h1
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, type: "spring", stiffness: 50 }}
          className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
        >
          Our design discoveries; exploring the art and science of marketing
        </motion.h1>
        <motion.p
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 50,
            delay: 0.5,
          }}
          className="font-montserrat font-semibold text-sm sm:text-xl mb-4 sm:mb-6 w-4/5"
        >
          Inspiring creativity, unveiling marketing secrets, and empowering
          businesses with artistic insights and strategies.
        </motion.p>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 50,
            delay: 1,
          }}
        >
          <PrimaryButton
            onClick={() => navigate("/contact")}
            icon={<img src={arrow} alt="arrow" className="" />}
            className="relative bg-primary text-white z-10"
          >
            Talk about a project
          </PrimaryButton>
        </motion.div>
      </div>
      <div className="lg:w-[40%]">
        <motion.img
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          src={hero}
          alt=""
          className="w-full"
        />
      </div>
    </div>
  );
};

export default BlogHeroSection;
