import React from "react";
import aboutSectionImg from "../../assets/images/about-section-image.webp";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <section className="w-full flex justify-center items-center">
      <div className="w-full mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
          <div className="max-w-lg sm:mb-20 relative">
            <motion.h2
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
              className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl relative z-10"
            >
              Who we are
            </motion.h2>
            <motion.p
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                delay: 0.5,
              }}
              className="mt-4 text-gray-600 text-lg"
            >
              We’re just like you. We know what it’s like to build a product,
              and dream of taking it to the next stage. We know what it’s like
              to take a huge leap of faith. Most of all, we know business isn’t
              something that you should have to figure out on your own. So,
              we’re here to help you. We’ll help you thrive in an increasingly
              complex landscape.
            </motion.p>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                delay: 1,
              }}
            >
              <div>
                <PrimaryButton
                  onClick={() => navigate("/who-we-are")}
                  className="relative bg-primary mt-10 z-10"
                  icon={<img src={arrow} alt="arrow" />}
                >
                  Learn more
                </PrimaryButton>
              </div>
            </motion.div>
          </div>
          <div className="-mb-20 md:mt-0 sm:-mb-36 overflow-hidden">
            <motion.img
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              whileHover={{ scale: 1.2 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              src={aboutSectionImg}
              alt="About Us"
              className="object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
