import React, { useEffect, useState } from "react";
import logo from "../assets/images/companylogo.svg";
import { SlArrowDown } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import SecondaryButton from "./common/SecondaryButton";
import { HashLink } from "react-router-hash-link";
import closeicon from "../assets/icons/closeicon.webp";

type LinkType = {
  name: string;
  path: string;
  href: string;
  lineHeight?: number;
  target?: string;
  options?: LinkType[];
  suboptions?: LinkType[];
  active?: boolean;
};

const links: LinkType[] = [
  {
    name: "Home",
    path: "/",
    href: "#",
    active: true,
  },
  {
    name: "Who we are",
    path: "/who-we-are",
    href: "",
    active: false,
  },
  {
    name: "What we do",
    href: "",
    path: "/what-we-do",
    active: false,
    options: [
      {
        name: "Design",
        path: "/design",
        lineHeight: 24,
        href: "/design",
        suboptions: [
          { name: "Logo Design", path: "", href: "/design#logo-design" },
          { name: "Brochuers & Flyers", path: "", href: "/design#broucher" },
          { name: "UI/UX Design", path: "", href: "/design#ui-ux-design" },
          { name: "Product Label Design", path: "", href: "/design#label" },
          {
            name: "Bussiness Card Design",
            path: "",
            href: "/design#bussiness-card",
          },
          { name: "Branding", path: "", href: "/design#branding" },
        ],
      },
      {
        name: "Web & App Development",
        href: "/web-app-development",
        path: "/web-app-development",
        suboptions: [
          {
            name: "Web Design",
            path: "",
            href: "/web-app-development#web-design",
          },
          {
            name: "App Design",
            path: "",
            href: "/web-app-development#app-design",
          },
        ],
      },
      {
        name: "Software Development",
        href: "/software-development",
        path: "/software-development",
        lineHeight: 200,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
      {
        name: "Marketing",
        href: "/marketing",
        path: "/marketing",
        lineHeight: 24,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
      {
        name: "Business Consultation",
        href: "/business-consultation",
        path: "/business-consultation",
        lineHeight: 24,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
    ],
  },
  {
    name: "Case Study",
    href: "",
    path: "/case-study",
  },
  {
    name: "Careers",
    href: "",
    path: "/careers",
  },
  {
    name: "Blog",
    href: "",
    path: "/blog",
  },
  {
    name: "Contact",
    href: "",
    path: "/contact",
  },
];

const Nav = () => {
  const [isOpen, setisOpen] = useState(false);
  const [activeOption, setActiveOption] = useState<string>("");
  const [navLinks, setNavLinks] = useState<LinkType[]>(links);

  const location = useLocation();

  const setActiveNav = (pageName: string) => {
    const newNavLinks = links.map((item) => {
      item.active = false;
      if (item.name === pageName) {
        item.active = true;
      }

      return item;
    });

    setNavLinks(newNavLinks);
  };

  const handleOptionClick = (linkName: string) => {
    setActiveOption((prev) => (prev === linkName ? "" : linkName)); // Use empty string instead of null
  };

  useEffect(() => {
    links.forEach((item) => {
      if (item.path === location.pathname) {
        setActiveNav(item.name);
      }
    });

    if (location.pathname === "/" && location.hash === "") {
      setActiveNav("Home");
    }
  }, [location.pathname, location.hash]);

  const handleSchedule = () => {
    window.open("https://calendly.com/idiksha", "_blank");
  };
 
  return (
    <nav className="py-2.5 relative">
      <div
        className="flex flex-wrap self-end items-center justify-between w-full
       px-2 sm:px-4 xl:px-8 max-w-screen-2xl mx-auto"
      >
        <Link to="/" className="relative">
          <img
            src={logo}
            className="h-14 sm:h-20 relative z-10"
            alt="TechQilla"
          />
        </Link>

        <div className="flex items-center lg:order-2">
          <SecondaryButton
            onClick={handleSchedule}
            className="text-sm sm:text-[16px] hover:bg-primary hover:border-transparent hover:text-white font-montserrat transition-all duration-300 relative z-10"
          >
            Schedule a Call
          </SecondaryButton>
          <button
            onClick={() => setisOpen(!isOpen)}
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="items-center font-montserrat lg:flex lg:w-auto lg:order-1"
          id="mobile-menu-2"
        >
          <ul
            className={`${
              isOpen ? "flex" : "hidden"
            } flex-col mt-4 font-medium items-center lg:flex-row lg:flex lg:space-x-8 lg:mt-0 z-50`}
          >
            {navLinks.map((link, index) => (
              <li key={index}>
                {link.options ? (
                  <div className="w-full py-2 lg:py-0">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleOptionClick(link.name);
                      }}
                    >
                      <span className="text-sm">{link.name}</span>
                      <SlArrowDown className="ml-1" fontSize={10} />
                    </div>
                    {activeOption === link.name && (
                      <div
                        className="z-[999] absolute left-0 top-0
                       w-screen h-screen origin-top-left bg-primary shadow-lg transition-all duration-500 items-center py-8 px-20"
                      >
                        <div className="flex justify-center">
                          <img
                            src={closeicon}
                            alt=""
                            className="p-4 rounded-full border border-white mb-4 cursor-pointer"
                            onClick={() => {
                              setActiveOption("");
                              setisOpen(false);
                            }}
                          />
                        </div>
                        <div className="flex flex-col sm:gap-10">
                          {link.options.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="group  relative sm:flex justify-between items-start"
                            >
                              <div className="w-full">
                                <div className="w-full ![&>div]:group-hover:flex flex-row items-center transition-all duration-500">
                                  <Link
                                    to={option.href}
                                    onClick={() => {
                                      setActiveOption("");
                                      setisOpen(false);
                                      setActiveNav("");
                                    }}
                                    className="block  px-4 py-2 text-white font-montserrat text-3xl font-bold"
                                  >
                                    {option.name}
                                  </Link>
                                </div>
                              </div>
                              <div
                                className={`hidden sm:block h-[1px] bg-white w-0 group-hover:w-full transition-all ease-in-out duration-500 origin-left  mt-4`}
                              ></div>

                              {option.suboptions && (
                                <ul
                                  className={`h-0 overflow-hidden transition-all duration-500 ease-in-out group-hover:h-auto group-hover:opacity-100 group-hover:visible group-hover:grid grid-cols-2 invisible opacity-0 bg-primary list-disc px-6 w-full`}
                                >
                                  {option.suboptions.map(
                                    (suboption, subIndex) => (
                                      <li
                                        key={subIndex}
                                        className="list-disc marker:text-white ml-5"
                                      >
                                        <HashLink
                                          to={suboption.href}
                                          onClick={() => setActiveOption("")}
                                          className="block py-2 text-sm text-white"
                                        >
                                          {suboption.name}
                                        </HashLink>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="relative z-10 group">
                    <Link
                      rel="noopener noreferrer"
                      to={link.path}
                      className="text-sm bg-transparent z-10"
                      onClick={() => {
                        setActiveNav(link.name);
                        setisOpen(false);
                      }}
                    >
                      {link.name}
                    </Link>

                    <div
                      className={`absolute top-6 h-1 rounded-full bg-primary transition-all duration-500 ease-in-out ${
                        link.active ? "w-[60%]" : "w-0 group-hover:w-[60%] "
                      }`}
                    ></div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
