import React from "react";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import {
  homehero1,
  homehero2,
  homehero3,
  homehero4,
} from "../../assets/images/index";
import SecondaryButton from "../common/SecondaryButton";
import { SlArrowDown } from "react-icons/sl";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col mt-10 items-center w-full px-4 sm:px-8">
      <div className="flex flex-col md:flex-row w-full justify-between pb-5 sm:pb-0">
        <div className="flex z-10 items-center py-5 md:w-[45%] md:pb-20 md:pt-10 md:pr-10 ">
          <div className="text-left font-montserrat">
            <div className="relative z-50">
              <motion.h3
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, type: "spring", stiffness: 100 }}
                className="font-montserrat text-secondary font-bold text-3xl sm:text-4xl xl:text-6xl"
              >
                Marketing
              </motion.h3>
              <motion.h3
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                  delay: 0.5,
                }}
                className="font-montserrat font-bold text-3xl sm:text-4xl xl:text-6xl relative z-50"
              >
                is no more a risk you have to take.
              </motion.h3>
            </div>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 1,
                type: "spring",
                stiffness: 100,
                delay: 1,
              }}
              className="mt-5 hidden sm:flex md:mt-8"
            >
              <div onClick={() => navigate("/contact")}>
                <PrimaryButton
                  className="relative z-10 bg-primary"
                  icon={<img src={arrow} alt="arrow" />}
                >
                  Hire Us
                </PrimaryButton>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="relative sm:-ml-20 sm:w-[55%]">
          <div className="flex relative gap-2 sm:gap-4 rounded-lg z-10 bg-white text-black w-full">
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
              className="w-[45%] relative z-30"
              src={homehero1}
              alt="heroImg"
            />
            <div className="flex flex-col gap-2 sm:gap-4 w-[55%]">
              <motion.img
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                  delay: 0.5,
                }}
                src={homehero2}
                alt=""
                className="h-[50%] relative z-0"
              />
              <div className="flex gap-2 sm:gap-4 h-[50%] ">
                <motion.img
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    stiffness: 100,
                    delay: 1,
                  }}
                  src={homehero3}
                  alt=""
                  className="w-[48%] relative z-10"
                />
                <motion.img
                  initial={{ x: 50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    stiffness: 100,
                    delay: 1.5,
                  }}
                  src={homehero4}
                  alt=""
                  className="w-[48%]"
                />
              </div>
            </div>
          </div>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 100,
              delay: 1,
            }}
            className="mt-5 flex sm:hidden md:mt-8"
          >
            <div onClick={() => navigate("/contact")}>
              <PrimaryButton
                icon={<img src={arrow} alt="arrow" className="relative z-10" />}
                className="relative bg-primary "
              >
                Hire Us
              </PrimaryButton>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="w-full hidden sm:flex justify-end py-10 items-center gap-4">
        <div onClick={() => navigate("/contact")}>
          <SecondaryButton className="px-10 hover:bg-primary hover:text-white hover:border-transparent transition-all duration-300 font-montserrat relative z-10">
            Let's Talk
          </SecondaryButton>
        </div>
        <div className="flex flex-col relative z-10">
          <div
            className="p-2 bg-primary mx-auto text-white rounded-full cursor-pointer"
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: "smooth",
              })
            }
          >
            <SlArrowDown />
          </div>

          <span className="text-[12px] mt-1">Scroll Down</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
