import React, { useState } from "react";
import PrimaryButton from "../../common/PrimaryButton";
import arrow from "../../../assets/images/arrow.svg";
import { motion } from "framer-motion";
import hero from "../../../assets/images/businesspagehero.webp";
import ContactPopup from "../../common/ContactPopup";

const BusinessPageHeroSection = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
        <div className="lg:w-[40%]">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
            className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
          >
            Business Consultation
          </motion.h1>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 0.5,
            }}
            className="font-montserrat font-semibold text-lg sm:text-xl mb-4 sm:mb-6 w-4/5"
          >
            Elevate your strategy with Techqilla’s business consultation—your
            partner in sustainable growth.
          </motion.p>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 1,
            }}
          >
            <PrimaryButton
              icon={<img src={arrow} alt="arrow" />}
              className="relative bg-primary z-10"
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Talk About a project
            </PrimaryButton>
          </motion.div>
        </div>
        <motion.div
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, type: "spring", stiffness: 200 }}
          className="lg:w-[60%] mb-4 lg:mb-0"
        >
          <img src={hero} alt="" className="w-full" />
        </motion.div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="business-consult"
          />
        </div>
      )}
    </>
  );
};

export default BusinessPageHeroSection;
