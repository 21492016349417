import React from "react";
import Nav from "./components/Nav";
import { Routes, Route } from "react-router-dom";
import "aos/dist/aos.css";
import HomePage from "./components/HomePage/HomePage";
import AboutPage from "./components/AboutPage/AboutPage";
import CaseStudy from "./components/CaseStudyPage/CaseStudy";
import Design from "./components/WhatWeDoPage/Design/Design";
import WebdevelopmentPage from "./components/WhatWeDoPage/WebDevelopmentPage/WebdevelopmentPage";
import SoftwareDevelopmentPage from "./components/WhatWeDoPage/SoftwareDevelopmentPage/SoftwareDevelopmentPage";
import MarketingPage from "./components/WhatWeDoPage/MarketingPage/MarketingPage";
import BusinessConsultancyPage from "./components/WhatWeDoPage/BusinessConsultancyPage/BusinessConsultancyPage";
import CareerPage from "./components/CareerPage/CareerPage";
import Footer from "./components/Footer";
import Contact from "./components/COntactUsPage/Contact";
import Blogpage from "./components/BlogPage/Blogpage";
import BlogPost from "./components/BlogPage/BlogPost";
import SoftSide from "./components/CaseStudyPage/CaseStudies/SoftSide/SoftSide";
import { AuthProvider } from "./context/AuthContext";
import Aes from "./components/CaseStudyPage/CaseStudies/Aes/Aes";
import TanksAndCover from "./components/CaseStudyPage/CaseStudies/tanksandcover/TanksAndCover";
import ScrollToTop from "./components/ScrollToTop";
import Ggp from "./components/CaseStudyPage/CaseStudies/ggp/Ggp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <AuthProvider>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/who-we-are" element={<AboutPage />} />
          <Route path="/design" element={<Design />} />
          <Route path="/case-study" element={<CaseStudy />} />
          <Route path="/case-study/softside" element={<SoftSide />} />
          <Route path="/case-study/aes" element={<Aes />} />
          <Route path="/case-study/tanksandcover" element={<TanksAndCover />} />
          <Route path="/case-study/ggp" element={<Ggp />} />
          <Route path="/web-app-development" element={<WebdevelopmentPage />} />
          <Route
            path="/software-development"
            element={<SoftwareDevelopmentPage />}
          />
          <Route path="/marketing" element={<MarketingPage />} />
          <Route
            path="/business-consultation"
            element={<BusinessConsultancyPage />}
          />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/blog" element={<Blogpage />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<>Not found</>} />
        </Routes>
        <Footer />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
