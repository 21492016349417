import React from "react";
import whoWeAre1 from "../../assets/images/who-we-are1.webp";
import whoWeAre2 from "../../assets/images/homejoin2.webp";
import whoWeAre3 from "../../assets/images/who-we-are3.webp";
import { motion } from "framer-motion";

const WhoWeAre = () => {
  return (
    <div className="bg-black flex flex-col justify-center">
      <div className="flex justify-center lg:h-[800px]">
        <div className="flex flex-col items-center  sm:flex-row justify-between  w-full font-gtAmerica text-white py-10 sm:py-20 px-4 sm:px-32 max-w-screen-2xl gap-4 sm:gap-0">
          <div className="flex w-full sm:w-[25%] h-[60%] self-start overflow-hidden">
            <motion.img
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              
              viewport={{ once: true }}
              transition={{ duartion: 1, delay: 0, ease: "easeInOut" }}
              src={whoWeAre1}
              alt="who-we-are"
              className="object-cover w-full object-top"
            />
          </div>
          <div className="sm:w-[55%] sm:h-full flex items-center overflow-hidden">
            <motion.img
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              
              viewport={{ once: true }}
              transition={{ duartion: 1, delay: 0.5, ease: "easeInOut" }}
              src={whoWeAre2}
              alt="who-we-are"
              className="flex sm:h-[70%] w-full object-cover"
            />
          </div>
          <div className="flex sm:items-end justify-end w-full  sm:w-[25%] h-[60%] self-end overflow-hidden">
            <motion.img
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
             
              viewport={{ once: true }}
              transition={{ duartion: 1, delay: 1, ease: "easeInOut" }}
              src={whoWeAre3}
              alt="who-we-are"
              className="h-full object-cover w-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-4 sm:px-32 text-center self-end">
        <motion.h2
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duartion: 0.5 }}
          className="text-3xl font-extrabold font-montserrat text-secondary mb-4 sm:mb-8 sm:text-5xl"
        >
          Who we are
        </motion.h2>
        <motion.p
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duartion: 0.5, delay: 0.5 }}
          className="text-xl mb-4 text-white font-semibold font-gtAmerica"
        >
          We’re a creative riot. We’re not constrained by briefs and are always
          looking for what lies outside the box.
        </motion.p>

        <motion.p
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duartion: 0.5, delay: 1 }}
          className="text-xl mb-20 text-white font-semibold font-gtAmerica"
        >
          We’re TechQilla. An amalgamation of Tech and Qilla, we stand at the
          intersection of giving your business a strong foundation while
          blending it with the latest technology.
        </motion.p>
      </div>
    </div>
  );
};

export default WhoWeAre;
