import React from "react";
import { motion } from "framer-motion";

type SkillTypes = {
  heading: string;
  items: {
    icon: string;
    name: string;
  }[];
};

const SkillComponent = ({ heading, items }: SkillTypes) => {
  return (
    <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center ">
      <div>
        <motion.h2
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-6xl"
        >
          {heading}
        </motion.h2>
      </div>
      <div className="flex flex-wrap gap-4 justify-center items-center">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center text-center"
          >
            <motion.div
              initial={{ rotateX: 180 }}
              whileInView={{ rotateX: 0 }}
              whileHover={{ rotate: 360 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              className="relative z-10 rounded-full h-24 w-24 border border-secondary flex gap-10  justify-center items-center mb-4 shadow-lg"
            >
              <motion.img src={item.icon} alt="" className="" />
            </motion.div>
            <p className="text-[#666666] font-montserrat text-base">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillComponent;
