import React from 'react'
import loader1 from "../assets/svgs/loader1.svg"
import loader2 from "../assets/svgs/loader2.svg"
import loader3 from "../assets/svgs/loader3.svg"

const Loader: React.FC = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen bg-white bg-opacity-75 relative">
      <img
        src={loader1}
        alt="Logo"
        className="opacity-0 fadeIn1 size-24 absolute top-0 bottom-0 left-0 right-0 m-auto "
      />
      <img
        src={loader2}
        alt="Logo"
        className="opacity-0 fadeIn2 size-24 absolute top-0 bottom-0 left-0 right-0 m-auto z-10"
      />
      <img
        src={loader3}
        alt="Logo"
        className="opacity-0 fadeIn3 size-24 absolute top-0 bottom-0 left-0 right-0 m-auto z-20"
      />
    </div>
  )
}

export default Loader