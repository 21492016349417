import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  icon?: JSX.Element;
}

const SecondaryButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`font-gtAmerica leading-none flex items-center gap-2 px-3 sm:px-8 py-3 sm:py-4 text-[16px] transition-all duration-200 font-medium bg-white border-2 border-secondary rounded-full secondary-btn-shadow hover:shadow-none focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75 ${
        className ? className : ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
