import React, { useState } from "react";
import BlogHeroSection from "./BlogHeroSection";
import Blogs from "./Blogs";



const Blogpage = () => {
  
  return (
    <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
      <BlogHeroSection />
      <Blogs/>
    </div>
  );
};

export default Blogpage;
