import React, { useState } from "react";
import img1 from "../../../assets/images/BusinessCard1.webp";
import img2 from "../../../assets/images/BusinessCard2.webp";
import arrow from "../../../assets/images/arrow.svg";
import PrimaryButton from "../../common/PrimaryButton";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const BusinessCard = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        id="bussiness-card"
        className="py-6 px-4 w-full sm:px-10 sm:py-20 flex flex-col justify-center items-center "
      >
        <div className="text-center mb-10">
          <motion.h2
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl"
          >
            Business Cards
          </motion.h2>
          <motion.p
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-lg font-montserrat font-medium"
          >
            Your brand deserves the best—make a statement with custom business
            cards from Techqilla!
          </motion.p>
        </div>
        <div className="flex flex-col sm:flex-row gap-5 justify-center items-center mb-6 sm:mb-10">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="relative"
          >
            <div className="absolute top-5 left-5 py-5 px-8 text-white border border-white rounded-3xl">
              <h3 className="font-montserrat font-bold text-xl">
                Alexander Executive Search
              </h3>
              <p className="font-montserrat">
                <span className="font-bold">Industry: </span>AC Services
                Provider
              </p>
            </div>
            <img src={img1} />
          </motion.div>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="relative"
          >
            <div className="absolute top-5 left-5 py-5 px-8  border border-white rounded-3xl">
              <h3 className="font-montserrat font-bold text-xl">
                Trans Screen
              </h3>
              <p className="font-montserrat">
                <span className="font-bold">Industry: </span>Electronic Products
              </p>
            </div>
            <img src={img2} />
          </motion.div>
        </div>
        <PrimaryButton
          className="self-center bg-primary relative z-10"
          icon={<img src={arrow} alt="arrow" />}
          onClick={() => setIsOpen(!modalIsOpen)}
        >
          Start a project with us
        </PrimaryButton>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="design"
          />
        </div>
      )}
    </>
  );
};

export default BusinessCard;
