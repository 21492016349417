import React from 'react'
type CaseStudy = {
  title: string;
  icon: string;
  name: string;
  date: number;
  className?: string
  subtitle: string;
};

const CaseHero = ({title, icon, name, date, subtitle, className}:CaseStudy) => {
  return (
    <div className='max-w-screen-2xl mx-auto'>
      <div className={`w-full py-8 px-5 sm:py-20 sm:px-10 lg:px-20  ${className}`}>
        <div className={`${className}`}>
          <h1
            className={`font-montserrat text-4xl sm:text-5xl font-bold mb-9 w-[80%] ${className}`}
          >
            {title}
          </h1>
          <div className="flex flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex lg:justify-center items-center gap-5 lg:gap-10 mb-4 sm:mb-0">
              <img src={icon} alt="softside" className={` ${className}`} />
              <h2
                className={`font-gtAmerica font-semibold text-2xl sm:text-3xl ${className}`}
              >
                {name}
              </h2>
            </div>
            <p className={`font-montserrat text-2xl opacity-50 ${className}`}>
              {date}
            </p>
            <p className={`font-montserrat text-2xl lg:w-[50%] ${className}`}>
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseHero