import React, { useState } from "react";
import { motion } from "framer-motion";
import arrow from "../../assets/images/arrow.svg";
import ContactPopup from "../common/ContactPopup";
import { ServiceType } from "../../types/types";

type GridTypes = {
  heading: string;
  list: {
    title: string;
    description: string;
    icon: string;
    serviceType: ServiceType;
  }[];
};

const GridComponent = ({ heading, list }: GridTypes) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [service, setService] = useState<ServiceType>("marketing");

  return (
    <>
      <div className="bg-primary overflow-hidden">
        <div className="w-full mx-auto max-w-screen-2xl flex flex-col py-6 px-4 sm:py-20 sm:px-10 ">
          {heading && (
            <h2
              data-aos="fade-right"
              data-aos-duration="1000"
              className="font-montserrat self-start text-white font-bold text-3xl sm:text-5xl xl:text-6xl mb-10"
            >
              {heading}
            </h2>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-12">
            {list.map((item, index) => (
              <motion.div
                
                
                key={item.title}
                className="flex flex-col justify-start items-start group hover:border-2 border-white p-4 rounded-3xl relative z-10 overflow-hidden transition-all duration-500"
              >
                <img src={item.icon} alt="" className="mb-2" />
                <h2 className="font-montserrat font-bold text-xl sm:text-3xl text-white mb-2 sm:mb-4">
                  {" "}
                  {item.title}
                </h2>
                <p className="font-montserrat font-semibold text-sm sm:text-xl text-white">
                  {item.description}
                </p>
                <button
                  onClick={() => {
                    setIsOpen(!modalIsOpen);
                    setService(item.serviceType);
                  }}
                  className="translate-y-[100px] group-hover:translate-y-0 text-white font-gtAmerica font-semibold flex gap-2 items-center mt-4 transition-all duration-500"
                >
                  {" "}
                  Let's talk about a project <img src={arrow} alt="arrow" />
                </button>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service={service}
          />
        </div>
      )}
    </>
  );
};

export default GridComponent;
