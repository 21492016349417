import React, { useState } from "react";
import { motion } from "framer-motion";
import phone from "../../assets/svgs/phoneicon.svg";
import email from "../../assets/svgs/mailicon.svg";
import location from "../../assets/svgs/locationicon.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import FormikField from "../common/FormikField";
import FormikErrorMessage from "../common/FormikErrorMessage";
import EditorTextArea from "../common/EditorTextArea";
import { ContactFormValues, ServiceType } from "../../types/types";
import { sendContactPageData } from "../../service/service";
import { toast } from "react-toastify";

const SubscribeSchema = Yup.object<ContactFormValues>({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  organization: Yup.string().required("Organization Name is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  message: Yup.string().required("message is required"),
  service: Yup.string().required("Service is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

type Service = {
  name: string;
  active: boolean;
  serviceType: ServiceType;
};

const services: Service[] = [
  {
    name: "Marketing",
    active: true,
    serviceType: "marketing",
  },
  {
    name: "Website and App development",
    active: false,
    serviceType: "web-app-dev",
  },
  {
    name: "Software Development",
    active: false,
    serviceType: "software-dev",
  },
  {
    name: "Business Consultation",
    active: false,
    serviceType: "business-consult",
  },
];

const initialVal: ContactFormValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  organization: "",
  message: "",
  email: "",
  service: "marketing",
};

const ContactHeroSection = () => {
  const [service, setService] = useState<ServiceType>("marketing");
  const [servicesList, setServices] = useState<Service[]>(services);

  const setActive = (btnName: string) => {
    const newbtn = servicesList.map((services) => {
      services.active = false;
      if (services.name === btnName || services.serviceType === btnName) {
        setService(services.serviceType);
        services.active = true;
      }
      return services;
    });

    setServices(newbtn);
  };

  const handleSubmit = async (
    values: ContactFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      const res = await sendContactPageData({
        ...values,
        from: "contact",
      });

      if (!res.status) {
        toast.error("Failed to subscribe, please try again later.");
        return;
      }

      const resData = res.response?.data;
      if (resData.status) {
        toast.success(resData.message);
      } else {
        toast.error(resData.message);
      }
    } catch (error) {
      setSubmitting(false);
      toast.error("Failed to subscribe, please try again later.");
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
      <div className="lg:w-[45%]">
        <motion.h1
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
          className="text-3xl sm:text-4xl xl:text-5xl font-extrabold font-montserrat mb-4 sm:mb-6"
        >
          Which service do you want to talk about
        </motion.h1>
        <ul className=" flex gap-4 flex-wrap list-none mb-8">
          {servicesList.map((item, index) => (
            <div key={index} className="flex cursor-pointer">
              <div className="p-[1px] custom-gradient2 rounded-full self-start">
                <li
                  key={index}
                  onClick={() => {
                    setService(item.serviceType);
                    setActive(item.name);
                  }}
                  className={`text-lg ${
                    item.active ? "relative !bg-primary text-white" : ""
                  } font-bold bg-white rounded-full px-4 py-2`}
                >
                  {item.name}
                </li>
              </div>
            </div>
          ))}
        </ul>
        <div className="grid lg:grid-cols-2 gap-5 lg:gap-8">
          <div className=" flex flex-col items-start font-gtAmerica">
            <div className="flex gap-2  justify-center items-center sm:mb-2">
              <div className="size-9 flex justify-center items-center rounded-full bg-primary">
                <img src={location} alt="" className="" />
              </div>
              <p className="font-montserrat font-medium text-xl">Address</p>
            </div>
            <a
              href="https://www.google.com/maps/dir//86,+Block+A,+Sector+4,+Noida,+Uttar+Pradesh+201301/@28.5837221,77.2386856,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390ce50002bb970b:0x7feb4a3b06765e30!2m2!1d77.321087!2d28.583747?entry=ttu&g_ep=EgoyMDI1MDExNC4wIKXMDSoASAFQAw%3D%3D"
              className="font-gtAmerica opacity-70 text-lg"
            >
              A-86, G-01, Sector 4, Noida, UP, 201301
            </a>
          </div>
          <div className=" flex flex-col items-start">
            <div className="flex gap-2  justify-center items-center sm:mb-2">
              <div className="size-9 flex justify-center items-center rounded-full bg-primary">
                <img src={email} alt="" className="" />
              </div>
              <p className="font-montserrat font-medium text-xl">Email</p>
            </div>
            <a
              href="mailto:hello@techqilla.com"
              className="font-gtAmerica opacity-70 text-lg relative z-10"
            >
              hello@techqilla.com
            </a>
          </div>
          <div className=" flex flex-col items-start">
            <div className="flex gap-2  justify-center items-center sm:mb-2">
              <div className="size-9 flex justify-center items-center rounded-full bg-primary">
                <img src={phone} alt="" className="" />
              </div>
              <p className="font-montserrat font-medium text-xl">Phone</p>
            </div>
            <a
              href="tel:+15093315518"
              className="font-gtAmerica opacity-70 text-lg relative z-10"
            >
              +1 (509) 331-5518
            </a>
            <a
              href="tel:+918279328685"
              className="font-gtAmerica opacity-70 text-lg relative z-10"
            >
              +91-8279328685
            </a>
          </div>
        </div>
      </div>
      <div className="lg:w-[50%] p-[1px] custom-gradient2 rounded-3xl w-full mb-10 sm:mb-0">
        <div className="bg-white flex gap-10 lg:mb-0 px-4 sm:px-10 py-4 sm:py-10 rounded-3xl w-full">
          <Formik
            initialValues={initialVal}
            enableReinitialize
            validationSchema={SubscribeSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col justify-center w-full">
                {isSubmitting ? (
                  <div className="text-center">Submitting...</div>
                ) : (
                  <>
                    <div className="mb-8 w-full lg:grid lg:grid-cols-2 gap-4">
                      <div className="w-full relative z-10">
                        <FormikField
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="Enter your First Name"
                          label="First Name"
                          required
                        />
                        <FormikErrorMessage name="firstName" />
                      </div>
                      <div className="w-full relative z-10">
                        <FormikField
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Enter Your Last Name"
                          label="Last Name"
                          required
                        />
                        <FormikErrorMessage name="lastName" />
                      </div>

                      <div className="w-full relative z-10">
                        <FormikField
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter Your Email"
                          label="Email"
                          required
                        />
                        <FormikErrorMessage name="email" />
                      </div>
                      <div className="w-full relative z-10">
                        <FormikField
                          type="text"
                          name="organization"
                          required
                          id="organization"
                          placeholder="Enter Your Organization Name"
                          label="Organization Name"
                        />
                        <FormikErrorMessage name="organization" />
                      </div>
                      <div className="w-full relative z-10">
                        <FormikField
                          type="text"
                          name="phoneNumber"
                          required
                          id="phoneNumber"
                          placeholder="Enter Your Phone Number"
                          label="Phone Number"
                        />
                        <FormikErrorMessage name="phoneNumber" />
                      </div>

                      <div className="w-full relative z-10">
                        <label
                          className="block mb-1 text-sm text-black font-medium font-montserrat"
                          htmlFor="service"
                        >
                          Service
                        </label>
                        <div className="p-[1px] custom-gradient2 rounded-lg">
                          <select
                            name="service"
                            id="service"
                            className="w-full px-4 py-3 bg-white rounded-lg shadow-sm font-montserrat focus:ring-0 focus:ring-blue-500"
                            onChange={(e) => {
                              setFieldValue("service", e.target.value);
                              setService(e.target.value as ServiceType);
                              setActive(e.target.value);
                            }}
                            value={service}
                          >
                            <option value="marketing">Marketing</option>
                            <option value="web-app-dev">
                              Website and App development
                            </option>
                            <option value="software-dev">
                              Software Development
                            </option>

                            <option value="business-consult">
                              Business Consultancy
                            </option>
                          </select>
                        </div>
                        <FormikErrorMessage name="service" />
                      </div>
                      <div className="col-span-2 relative z-10">
                        <EditorTextArea
                          placeholder="Type your message here"
                          name="message"
                          required
                          label="Your Message"
                        />
                        <FormikErrorMessage name="message" />
                      </div>
                    </div>
                    <PrimaryButton
                      type="submit"
                      icon={<img src={arrow} alt="" />}
                      disabled={isSubmitting}
                      className="relative bg-primary border border-opacity-50 border-white text-white justify-center z-10"
                    >
                      Submit
                    </PrimaryButton>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactHeroSection;
