import { sendMailClient, axiosClient } from "../components/api/axiosClient";
import { BlogCategory, BlogType, ContactFormValues } from "../types/types";


export const fetchBlogs = async (): Promise<{
  blogs: BlogType[] | null;
  status: number;
}> => {
  try {
    const response = await axiosClient.get(`/techqilla-blogs`, {
      params: {
        populate: {
          techqilla_authors: {
            populate: "image",
          },
          techqilla_categories: {
            populate: "*",
          },
          cover: {
            populate: "*",
          },
        },
      },
    });
    if (response.status === 200) {
      return { blogs: response.data.data, status: response.status };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { blogs: null, status: 500 };
};

export const fetchBlogBySlug = async (
  slug: string,
): Promise<{
  blogs: BlogType | null;
  status: number;
}> => {
  try {
    const response = await axiosClient.get(`/techqilla-blogs`, {
      params: {
        "filters[slug][$eq]": slug,
        populate: "*",
      },
    });
    if (response.status === 200) {
      return { blogs: response.data.data[0], status: response.status };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { blogs: null, status: 500 };
};

export const fetchCategories = async (): Promise<{
  categories: BlogCategory[] | null;
  status: number;
}> => {
  try {
    const response = await axiosClient.get(`/techqilla-categories`);
    if (response.status === 200) {
      return { categories: response.data.data, status: response.status };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { categories: null, status: 500 };
};

export const fetchJobById = async (id: string) => {
  try {
    const response = await axiosClient.get(`/jobs/get/${id}`);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const sendContactPageData = async (
  values: ContactFormValues & { from: string },
) => {
  try {
    const response = await sendMailClient.post("/mail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const sendNewsLetterData = async (
  values: { email: string; firstName: string } & { from: string },
) => {
  try {
    const response = await sendMailClient.post("/mail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};


