import React, { useState } from "react";
import { motion } from "framer-motion";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import RecruitmentModal from "./RecruitmentModal";

type PositionProps = {
  heading: string;
  description: string;
};

const position: PositionProps[] = [
  {
    heading: "Content Writer",
    description: "We’re looking for a content writer to join our team.",
  },
  {
    heading: "Brand Strategist",
    description:
      "We’re looking for a brand strategist to join our team. We value creativity and innovation.",
  },
  {
    heading: "Video Editor",
    description: "We’re looking for a video editor to join our team.",
  },
  {
    heading: "Business Development Executive",
    description:
      "We’re looking for a business development executive to join our team.",
  },
];

const Position = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [positions, setPositions] = useState<PositionProps[]>(position);
  return (
    <div className="w-full py-6 sm:py-20 px-4 sm:px-6 lg:px-8">
      <motion.h2
        initial={{ y: -100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="text-4xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl"
      >
        Open Positions
      </motion.h2>
      <div className=" flex flex-col gap-10">
        {positions.map((post, index) => (
          <div
            key={index}
            className="border-2 border-black sm:flex justify-between"
          >
            <div className="py-4 sm:py-6 px-7 font-montserrat">
              <h3 className="text-3xl font-montserrat font-bold mb-2">
                {post.heading}
              </h3>
              <p className="font-montserrat mb-4 text-lg font-medium">
                {post.description}
              </p>
              <div className="flex flex-col sm:flex-row gap-6 sm:gap-10">
                <div className="rounded-full  px-4 py-2 border-2 font-semibold text-lg border-black">
                  Work From Office
                </div>
                <div className="rounded-full px-4 py-2 border-2 font-semibold text-lg border-black">
                  Full Time
                </div>
              </div>
            </div>
            <div className="py-4 sm:py-6 px-6">
              <PrimaryButton
                onClick={() => {
                  setTitle(post.heading);
                  setIsOpen(true);
                }}
                icon={<img src={arrow} alt="arrow" />}
                className="relative bg-primary z-10"
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        ))}
      </div>
      <RecruitmentModal
        isOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        title={title}
      />
    </div>
  );
};

export default Position;
