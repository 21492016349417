import React from 'react'
type DesignType = {
  heading: string
  link: string
  linkName: string
  img: string
  bgcolor: string
}

const Design = ({heading, link, img, bgcolor, linkName}:DesignType) => {
  return (
    <div className={`${bgcolor}`}>
      <div className='max-w-screen-2xl mx-auto px-5 lg:px-20 pt-8 lg:pt-20'>
        <div className='flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between mb-6'>
          <h1 className='font-montserrat text-white text-3xl font-bold'>
            { heading}
          </h1>
          <p className='flex gap-2 text-white font-gtAmerica items-center'>Link to Website :- <a href={`${link}`} className='border border-white py-2 px-3 font-montserrat rounded-full'>{ linkName}</a></p>
        </div>
        <div className='flex justify-center items-center'>
          <img src={img} className='' alt='' />
        </div>
      </div>

    </div>
  )
}

export default Design