import React from "react";
import CaseHeroSection from "./CaseHeroSection";
import {
  homeproject1,
  homeproject2,
  homeproject3,
  homeproject4,
  homeproject5,
  homeproject7,
} from "../../assets/images/index";
import Slider from "../common/Slider";
import Cases from "./Cases";

const CaseStudy = () => {
  const images = [
    {
      name: homeproject1,
      link: "https://www.softsidebyhunt.com/",
    },
    {
      name: homeproject2,
      link: "https://lonestarscent.com/",
    },
    {
      name: homeproject3,
      link: "https://powersquared.tech/",
    },
    {
      name: homeproject4,
      link: "https://www.aesrecruitment.com/",
    },
    {
      name: homeproject5,
      link: "https://www.tanksandcovers.com/",
    },
    {
      name: homeproject7,
      link: "https://psycheethos.com/",
    },
  ]
  return (
    <>
      <div className=" mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <CaseHeroSection />
      </div>
      <Cases />
      <Slider
        className="bg-primary text-white "
        heading="Website Design"
        items={images}
      />
    </>
  );
};

export default CaseStudy;
