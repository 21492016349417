import React from 'react'
import arrow2 from "../../assets/images/arrowprimary.svg";
import bg from "../../assets/svgs/casebgsvg.svg";
import PrimaryButton from "../common/PrimaryButton";
import { useNavigate } from 'react-router-dom';

const Data = () => {
  const navigate = useNavigate()
  return (
    <div className="max-w-screen-2xl mx-auto pb-20 px-5 lg:px-20">
      <div className="bg-primary rounded-3xl flex justify-center items-center h-[400px] mt-20 relative">
        <div className="flex flex-col font-montserrat font-bold text-3xl lg:text-5xl text-white lg:text-center px-5 lg:px-0">
          <h4 className="mb-6 relative z-20">
            Facing Data Challenges? <br />
            Overcome them with our expertise
          </h4>
          <div
            onClick={() => navigate("/contact")}
            className="self-start lg:self-center relative z-20"
          >
            <PrimaryButton
              className="relative bg-white !text-primary  z-20"
              icon={<img src={arrow2} />}
            >
              Get in Touch
            </PrimaryButton>
          </div>
        </div>
        <img src={bg} className="absolute left-0 bottom-0 z-10" />
      </div>
    </div>
  );
}

export default Data