import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import PrimaryButton from "./PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import ContactPopup from "./ContactPopup";

type SliderProps = {
  id?: string;
  heading: string;
  items: {
    name: string;
    link: string;
  }[];
  className?: string;
  buttonData?: {
    name: string;
    className: string;
  };
};

const Slider = ({ heading, items, className, buttonData, id }: SliderProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={`w-full py-6 px-4 sm:py-20 sm:px-10 lg:px-20  ${className}`}
        id={id}
      >
        <h3
          className={`font-montserrat mx-auto max-w-screen-2xl  font-extrabold text-3xl sm:text-5xl xl:text-6xl mb-4 sm:mb-10 `}
        >
          {heading}
        </h3>

        <div className="mx-auto max-w-screen-2xl w-full flex justify-center items-center">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            className="flex justify-center items-center "
            pagination={{
              type: "progressbar",
              el: ".pagination-progress",
            }}
            autoplay={true}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            loop
          >
            {" "}
            {items.map((item, index) => (
              <SwiperSlide className="!flex justify-center z-10" key={index}>
                {item.link ? (
                  <a
                    className="rounded-3xl overflow-hidden"
                    href={item.link || ""}
                    target="blanck"
                  >
                    <img className="w-full object-cover" src={item.name}  alt="swiper"/>
                  </a>
                ) : (
                  <img className="w-full object-cover" src={item.name} alt="swiper"/>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {buttonData && (
          <div className="flex justify-center items-center mt-8 sm:mt-12">
            <PrimaryButton
              className={buttonData.className}
              icon={<img src={arrow} alt="arrow" className="" />}
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              {buttonData.name}
            </PrimaryButton>
          </div>
        )}
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center z-50">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="marketing"
          />
        </div>
      )}
    </>
  );
};

export default Slider;
