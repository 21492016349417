import React, { useEffect } from "react";
import Creators from "./Creators";
import Culture from "./Culture";
import OurValues from "./OurValues";
import ScrollPathAnimation from "./ScrollPath";
import WhoWeAre from "./Who-we-are";
import AboutPageHeroSection from "./AboutPageHeroSection";
import SmallScrollPath from "./SmallScrollPath";

const AboutPage = () => {
  return (
    <>
      <div className="overflow-hidden">
        <AboutPageHeroSection />
        <WhoWeAre />
        <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
          <OurValues />
        </div>
        <ScrollPathAnimation />
        <SmallScrollPath />

        <Creators />
        <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center bg-primary">
          <Culture />
        </div>
      </div>
    </>
  );
};

export default AboutPage;
