import React from "react";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikField from "../common/FormikField";
import FormikErrorMessage from "../common/FormikErrorMessage";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import EditorTextArea from "../common/EditorTextArea";
import { ServiceType } from "../../types/types";

type ModelProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ServiceProps = {
  service: ServiceType;
};

type FormValues = {
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  query: string;
  phoneNumber: string;
  service: ServiceType;
};

const customStyles = {
  overlay: {
    zIndex: 1000, // Make sure overlay also has high z-index
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "75vh",
    width: "70vw",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "30px",
  },
};

const subscribeSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  organization: Yup.string().required("Organization Name is required"),
  phoneNumber: Yup.number().required("Mobile Number is required"),
  query: Yup.string().required("Query is required"),
  service: Yup.string().required("Service is required"),
});

const ContactPopup = ({
  isOpen,
  setIsOpen,
  service,
}: ModelProps & ServiceProps) => {
  const handleSubmit = async (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);
  };

  const defaultValues: FormValues = {
    firstName: "",
    lastName: "",
    query: "",
    organization: "",
    email: "",
    phoneNumber: "",
    service: service,
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Formik
          initialValues={defaultValues}
          validationSchema={subscribeSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            
            setSubmitting(false);
            handleSubmit(values, setSubmitting);
            alert(`Thank you for subscribing, ${values.firstName}`);
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col justify-center  w-full px-1 py-1 sm:px-5 lg:px-10 sm:py-4">
              <div className="flex justify-between mb-8">
                <h3 className="font-montserrat font-extrabold text-[20px] sm:text-4xl">
                  Fill The Form
                </h3>
                <button onClick={() => setIsOpen(false)}>
                  <svg
                    width="69"
                    height="69"
                    viewBox="0 0 69 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-10"
                  >
                    <circle
                      cx="34.3107"
                      cy="34.3107"
                      r="33.8107"
                      stroke="black"
                    />
                    <path
                      d="M35.2931 34.5502L47.3521 22.59C47.46 22.483 47.5456 22.356 47.6039 22.2162C47.6623 22.0764 47.6924 21.9266 47.6924 21.7752C47.6924 21.6239 47.6623 21.4741 47.6039 21.3343C47.5456 21.1945 47.46 21.0675 47.3521 20.9605C47.2442 20.8535 47.1161 20.7686 46.9752 20.7107C46.8342 20.6528 46.6832 20.623 46.5306 20.623C46.378 20.623 46.227 20.6528 46.086 20.7107C45.945 20.7686 45.817 20.8535 45.7091 20.9605L33.6501 32.7977L21.5911 20.8375C21.4832 20.7305 21.3551 20.6456 21.2142 20.5877C21.0732 20.5298 20.9222 20.5 20.7696 20.5C20.617 20.5 20.466 20.5298 20.325 20.5877C20.1841 20.6456 20.056 20.7305 19.9481 20.8375C19.8402 20.9445 19.7546 21.0715 19.6963 21.2113C19.6379 21.3511 19.6078 21.5009 19.6078 21.6523C19.6078 21.8036 19.6379 21.9534 19.6963 22.0932C19.7546 22.233 19.8402 22.36 19.9481 22.467L32.0071 34.5502L19.9481 46.3874C19.8384 46.4934 19.7513 46.6201 19.6917 46.76C19.6322 46.8999 19.6016 47.0503 19.6016 47.2022C19.6016 47.354 19.6322 47.5044 19.6917 47.6443C19.7513 47.7842 19.8384 47.9109 19.9481 48.0169C20.0502 48.1273 20.175 48.2146 20.314 48.2729C20.4531 48.3313 20.6032 48.3593 20.7541 48.3551C20.9097 48.3598 21.0645 48.3321 21.2087 48.2738C21.3528 48.2156 21.4831 48.1281 21.5911 48.0169L33.6501 36.0567L45.7091 48.0169C45.8112 48.1273 45.936 48.2146 46.075 48.2729C46.2141 48.3313 46.3642 48.3593 46.5151 48.3551C46.6709 48.3617 46.8264 48.3349 46.9708 48.2766C47.1152 48.2182 47.2453 48.1297 47.3521 48.0169C47.4618 47.9109 47.5489 47.7842 47.6085 47.6443C47.668 47.5044 47.6986 47.354 47.6986 47.2022C47.6986 47.0503 47.668 46.8999 47.6085 46.76C47.5489 46.6201 47.4618 46.4934 47.3521 46.3874L35.2931 34.5502Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>

              <div className="mb-8 w-full lg:grid lg:grid-cols-2 gap-4">
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block mb-1 text-sm font-medium font-montserrat "
                  >
                    Name
                  </label>
                  <FormikField
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter First Name"
                    required
                  />
                  <FormikErrorMessage name="firstName" />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block mb-1 text-sm  font-medium font-montserrat"
                  >
                    Last Name
                  </label>
                  <FormikField
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter Last Name"
                    required
                  />
                  <FormikErrorMessage name="lastName" />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm  font-medium font-montserrat"
                  >
                    Email
                  </label>
                  <FormikField
                    type="email"
                    name="email"
                    required
                    id="email"
                    placeholder="Email"
                  />
                  <FormikErrorMessage name="email" />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block mb-1 text-sm  font-medium font-montserrat"
                  >
                    Mobile Number
                  </label>
                  <FormikField
                    type="text"
                    name="phoneNumber"
                    required
                    id="phoneNumber"
                    placeholder="Enter Mobile Number"
                  />
                  <FormikErrorMessage name="phoneNumber" />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block mb-1 text-sm  font-medium font-montserrat"
                  >
                    Organization Name
                  </label>
                  <FormikField
                    type="text"
                    name="organization"
                    required
                    id="address"
                    placeholder="Organization"
                  />

                  <FormikErrorMessage name="organization" />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="service"
                    className="block mb-1 text-sm font-medium font-montserrat"
                  >
                    Select Service
                  </label>
                  <select
                    name="service"
                    onChange={(e) => setFieldValue("service", e.target.value)}
                    className="w-full px-4 py-3 bg-white rounded-lg shadow-sm font-montserrat focus:ring-0 focus:ring-blue-500"
                    value={values.service}
                  >
                    <option value="marketing">Marketing</option>
                    <option value="business-consult">
                      Business Consultancy
                    </option>
                    <option value="design">Design</option>
                    <option value="web-app-dev">Web App Development</option>
                    <option value="software-dev">Software Development</option>
                  </select>

                  <FormikErrorMessage name="service" />
                </div>
                <div className="col-span-2">
                  <EditorTextArea
                    placeholder="Type your query here"
                    name="query"
                    required
                    label="Your Query"
                  />
                  <FormikErrorMessage name="query" />
                </div>
              </div>
              <PrimaryButton
                type="submit"
                icon={<img src={arrow} alt="" />}
                disabled={isSubmitting}
                className="relative bg-primary border border-opacity-50 border-white text-white justify-center self-start"
              >
                Submit
              </PrimaryButton>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ContactPopup;
