import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  icon?: JSX.Element;
}

const PrimaryButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`font-gtAmerica flex ${
        props.icon && "gap-2"
      } hover:tracking-widest [&>img]:hover:translate-x-2 transition-custom items-center px-6 py-3 text-[16px] transition-all duration-200 text-white bg-black rounded-lg shadow-lg hover:shadow-none focus:outline-none focus:ring-0${
        className ? className : ""
      }`}
      {...props}
    >
      {children}
      <span className="transition-custom">{props.icon}</span>
    </button>
  );
};

export default PrimaryButton;
