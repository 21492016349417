import React, { useState } from "react";
import PrimaryButton from "../../common/PrimaryButton";
import arrow from "../../../assets/images/arrow.svg";
import { marketinghero } from "../../../assets/images/index";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const MarketingHeroSection = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
        <div className="lg:w-[40%]">
          <motion.h1
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
            className="text-6xl font-extrabold font-montserrat  mb-6"
          >
            Marketing
          </motion.h1>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 0.5,
            }}
            className="font-montserrat font-semibold text-xl mb-6 w-4/5"
          >
            Fuel your brand’s success with Techqilla's creative marketing
            strategies designed for real impact.
          </motion.p>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 200,
              delay: 1,
            }}
          >
            <PrimaryButton
              icon={<img src={arrow} alt="arrow" />}
              className="relative z-10 bg-primary "
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Talk About a project
            </PrimaryButton>
          </motion.div>
        </div>
        <div className="lg:w-[60%] mb-4 lg:mb-0">
          <motion.img
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            src={marketinghero}
            alt=""
            className="w-full"
          />
        </div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="marketing"
          />
        </div>
      )}
    </>
  );
};

export default MarketingHeroSection;
