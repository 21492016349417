import React from "react";
import { IoStar } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

type Review = {
  description: string;
  name: string;
  position: string;
};

const reviews: Review[] = [
  {
    name: "Nicholas Donarski",
    position: "Founder",
    description:
      "I am amazed to see the quality of work that Techqilla did for me. Awesome Designers and awesome team.",
  },

  {
    name: "Jonah Havel",
    position: "Software Developer and Military Operation",
    description:
      "It's a  very professional company that leaves no requirement and opinion pending. The staff is also very friendly and innovative.",
  },
  {
    name: "Carl G Morris",
    position: "CEO (Envision Travel Group)",
    description:
      "Wonderful communication, rapid response, excellent listner,detailed oriented, great suggestions, exercise foresight, goal oriented, time conscious having your best  interest in mind",
  },
  {
    name: "Shawn Piatz",
    position: "VPO",
    description:
      "Techqilla was fantastic to work  with! Diksha, Vishesh, Sakshi and the whole staff are creative, Intelligent people who helped us meet  our need and expand our audience on social media.",
  },
  {
    name: "Lucas Hamrick",
    position: "Chief Executive Office",
    description:
      "Honestly I am so satisfied with thier work after wandering companies  to companies I finally found the perfect partner that provides great strategies for my business",
  },
];

const Reviews = () => {
  return (
    <div className="bg-black text-white py-20 px-4 sm:px-20 flex flex-col items-center gap-4 justify-between w-full z-0 ">
      <div className="max-w-screen-2xl w-full mb-8">
        <h2 className="text-3xl font-extrabold font-montserrat text-white sm:mb-10 sm:text-5xl">
          Success Stories
        </h2>
        <div className="flex w-full flex-col justify-between sm:items-end sm:flex-row gap-10">
          <div className="flex gap-4">
            <div className="flex items-center gap-2">
              <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                12
              </h2>
              <p className="text-sm">Verified reviews</p>
            </div>
            <div className="flex items-center gap-2">
              <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                5.0
              </h2>
              <p className="text-sm">Ratings</p>
            </div>
          </div>
          <p className="text-xl mb-10 font-semibold sm:w-[350px]">
            Our <span className="text-primary">clients’ reviews </span> clearly
            demonstrate how efficient our collaborative approach is.
          </p>
        </div>
        <div className="w-full h-[100px] rounded-r-full rounded-l-full -mt-10 sm:border-b-2"></div>
      </div>
      <div className="max-w-screen-2xl w-full flex justify-center items-center">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          className="flex justify-center items-center"
          pagination={{
            el: ".swiper-pagination",
            type: "progressbar",
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop
          autoplay={true}
        >
          {reviews.map((review, index) => (
            <SwiperSlide className="!flex justify-center" key={index}>
              <div className="h-[300px] sm:h-[400px] font-gtAmerica bg-white [&>*]:hover:text-white sm:max-w-[350px] rounded-2xl sm:rounded-[40px] sm:px-4 py-4 sm:py-8 hover:bg-primary transition-custom">
                <div className="flex items-center gap-2 text-primary mb-12 mx-4 sm:mx-6">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <span>5.0</span>
                </div>
                <p className="text-sm sm:text-lg text-black mx-4 sm:mx-6">
                  {review.description}
                </p>
                <p className="font-bold pt-8 text-black text-sm mx-4 sm:mx-6">
                  {review.name}
                </p>
                <p className="font-bold pt-2 text-black text-sm mx-4 sm:mx-6">
                  {review.position}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="relative w-full max-w-[300px]">
        <div className="swiper-pagination pagination-progress h-2"></div>
      </div>

      <div className="flex gap-2 relative z-10">
        <div className="swiper-button-prev p-4 bg-black hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-gray-400">
          <FaArrowRightLong className="text-white rotate-180" />
        </div>

        <div className="swiper-button-next p-4 bg-black hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-gray-400">
          <FaArrowRightLong className="text-white" />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
