import React from "react";
import {
  javaScriptIcon,
  cssIcon,
  angularIcon,
  React1Icon,
  htmlIcon,
  vueIcon,
  ionicIcon,
  xamarinIcon,
  flutterIcon,
  swiftIcon,
  iosNativeIcon,
  androidNativeIcon,
  kotlinIcon,
} from "../../../assets/icons/index";
import {
  webicon1,
  webicon2,
  webicon3,
  webicon4,
  webicon5,
  homeproject1,
  homeproject2,
  homeproject3,
  homeproject4,
  homeproject5,
  homeproject7,
  appswiper1,
  appswiper2,
  appswiper3,
  appicon2,
  appicon3,
  appswiper4,
} from "../../../assets/images/index";
import WebDevelopmentHeroSection from "./WebDevelopmentHeroSection";
import GridComponent from "../../common/GridComponent";
import Slider from "../../common/Slider";
import SkillComponent from "../../common/SkillComponent";
import WorkProgress from "./WorkProgress";
import { ServiceType } from "../../../types/types";

type ListType = {
  icon: string;
  title: string;
  description: string;
  serviceType: ServiceType;
};

const WebdevelopmentPage = () => {
  const listitem: ListType[] = [
    {
      icon: webicon1,
      title: "Web Design",
      serviceType: "web-app-dev",
      description:
        "We focus on breaking down the user journey and translating that with great design that ensure that your audience doesn’t bounce back and is hooked from the second they land on it.",
    },
    {
      icon: webicon2,
      title: "Static Website",

      serviceType: "web-app-dev",
      description:
        "Static websites that’ll leave your audience with an experience that aims at getting qualified leads and accelerating your growth.",
    },
    {
      icon: webicon3,
      title: "E-Commerce Website",

      serviceType: "web-app-dev",
      description:
        "Whether it’s a bakery business or a SaaS product, the whole world is sitting online and has a website and that’s why your e-commerce business should too.",
    },
    {
      icon: webicon4,
      title: "Dynamic Website",

      serviceType: "web-app-dev",
      description:
        "Functionalities and design that go hand in hand, making the crawl experience interactive and engaging.",
    },
    {
      icon: webicon5,
      title: "Landing Pages",

      serviceType: "web-app-dev",
      description:
        "Landing pages are the central place where all of your marketing and sales efforts lead to. They need to communicate the purpose and lead to action.",
    },
  ];

  const items = [
    {
      name: homeproject1,
      link: "https://www.softsidebyhunt.com/",
    },
    {
      name: homeproject2,
      link: "https://lonestarscent.com/",
    },
    {
      name: homeproject3,
      link: "https://powersquared.tech/",
    },
    {
      name: homeproject4,
      link: "https://www.aesrecruitment.com/",
    },
    {
      name: homeproject5,
      link: "https://www.tanksandcovers.com/",
    },
    {
      name: homeproject7,
      link: "https://psycheethos.com/",
    },
  ];
  const appswiperitems = [
    {
      name: appswiper1,
      link: "",
    },
    {
      name: appswiper2,
      link: "",
    },
    {
      name: appswiper3,
      link: "",
    },
    {
      name: appswiper4,
      link: "",
    },
  ];

  const webskill = [
    {
      icon: javaScriptIcon,
      name: "JavaScript",
    },
    {
      icon: cssIcon,
      name: "CSS",
    },
    {
      icon: angularIcon,
      name: "Angular",
    },
    {
      icon: React1Icon,
      name: "React.Js",
    },
    {
      icon: htmlIcon,
      name: "HTML5",
    },
    {
      icon: vueIcon,
      name: "VueJS",
    },
    {
      icon: ionicIcon,
      name: "Ionic",
    },
  ];

  const appskill = [
    {
      icon: React1Icon,
      name: "React Native",
    },
    {
      icon: xamarinIcon,
      name: "Xamarin",
    },
    {
      icon: flutterIcon,
      name: "Flutter",
    },
    {
      icon: swiftIcon,
      name: "Swift",
    },
    {
      icon: iosNativeIcon,
      name: "Ios Native",
    },
    {
      icon: androidNativeIcon,
      name: "Android Native",
    },
    {
      icon: kotlinIcon,
      name: "Kotlin",
    },
    {
      icon: ionicIcon,
      name: "Ionic",
    },
  ];

  const appitem: ListType[] = [
    {
      icon: webicon1,
      serviceType: "web-app-dev",
      title: "Web App Development",
      description:
        "From concept to launch, Techqilla’s web application development transforms ideas into robust, high-performance apps. Let’s create a solution that scales with your business.",
    },
    {
      icon: appicon2,
      serviceType: "web-app-dev",
      title: "Mobile App Development",
      description:
        "Get ahead of the competition with custom mobile development from Techqilla. We create apps that drive user engagement and business growth.",
    },
    {
      icon: appicon3,
      title: "Desktop App Development",
      serviceType: "web-app-dev",
      description:
        "Unlock new possibilities with custom desktop apps by Techqilla. Tailored solutions to fit your business needs and optimize performance.",
    },
  ];
  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <WebDevelopmentHeroSection />
      </div>
      <GridComponent heading="Web Development" list={listitem} />
      <WorkProgress />
      <Slider
        id="web-design"
        heading="Web Design"
        items={items}
        buttonData={{
          name: "Start a project with us",
          className: "relative bg-primary text-white",
        }}
      />

      <SkillComponent heading="Website Designing Software" items={webskill} />

      <GridComponent heading="Application Development" list={appitem} />

      <Slider
        id="app-design"
        heading="App Designs"
        items={appswiperitems}
        buttonData={{
          name: "Start a project with us",
          className: "relative bg-primary text-white",
        }}
      />

      <SkillComponent heading="Mobile Designing Software" items={appskill} />
    </>
  );
};

export default WebdevelopmentPage;
