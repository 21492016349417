import React, { useRef } from "react";
import portfolioImg from "../../assets/images/portfolioImg.webp";
import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import {
  homeproject1,
  homeproject2,
  homeproject3,
  homeproject4,
  homeproject5,
  homeproject7,
} from "../../assets/images/index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

type projectType = {
  icon: string;
  link: string;
  alt: string;
};

const project: projectType[] = [
  {
    icon: homeproject1,
    link: "https://www.softsidebyhunt.com/",
    alt: ""
  },
  {
    icon: homeproject2,
    link: "https://lonestarscent.com/",
    alt: "lone star scent"
  },
  {
    icon: homeproject3,
    link: "https://powersquared.tech/",
    alt: "power squared"
  },
  {
    icon: homeproject4,
    link: "https://www.aesrecruitment.com/",
    alt: "aes recruitment"
  },
  {
    icon: homeproject5,
    link: "https://www.tanksandcovers.com/",
    alt: "tanks and covers"
  },
  {
    icon: homeproject7,
    link: "https://psycheethos.com/",
    alt: "psycheethos"
  },
];
function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [1, 0], [-1200, 400]);
}

const Portfolio = () => {
  const slider = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start center", "center start"],
  });
  const y = useParallax(scrollYProgress, 300);

  useGSAP(() => {
    const timeout = setTimeout(() => {
      gsap.context(() => {
        let panels = gsap.utils.toArray<HTMLDivElement>(".panel");
        let sliderWidth = slider.current?.offsetWidth || 0;

        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            start: "top top",
            end: () => `+=${sliderWidth}`,
          },
        });
      }, ref);

      ScrollTrigger.refresh();
    }, 1000); // Small delay to ensure DOM is ready

    return () => clearTimeout(timeout);
  }, [ref]);

  return (
    <>
      <div
        ref={ref}
        className=" w-full h-panel bg-fit overflow-hidden max-w-screen-2xl mx-auto hidden lg:block"
        style={{
          backgroundImage: `url('${portfolioImg}')`,
        }}
      >
        <div className="text-white py-20 px-4 sm:px-20 flex flex-col gap-4 justify-between w-full lg:mb-10">
          <motion.div style={{ y }}>
            <h2 className="text-3xl font-extrabold font-montserrat text-white sm:mb-10 sm:text-5xl">
              Portfolio
            </h2>
            <div className="flex w-full flex-col justify-between sm:items-end sm:flex-row gap-10">
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                    42
                  </h2>
                  <p className="text-sm">Completed projects</p>
                </div>
                <div className="flex items-center gap-2">
                  <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                    30+
                  </h2>
                  <p className="text-sm">Happy clients</p>
                </div>
              </div>
              <p className="text-xl mb-10 font-semibold sm:w-[350px]">
                Our works are a blend of innovative thinking and practical
                solutions, ensuring they are both unique and effective.
              </p>
            </div>
          </motion.div>
        </div>
        <div ref={slider} className="lg:flex lg:h-full  w-full relative z-10">
          {project.map((item, index) => (
            <div
              className="w-full flex-shrink-0 panel relative group"
              key={index}
            >
              <img
                src={item.icon}
                className="w-full h-full object-cover"
                alt={`Project ${index + 1}`}
              />
              <div className="w-full h-full bg-black invisible group-hover:visible absolute top-0 flex justify-center items-center bg-opacity-30 transition-all duration-300 z-20">
                <a className="" href={item.link} target="_blank">
                  <button className="size-40 flex justify-center items-center bg-white font-gtAmerica text-center rounded-full  transition-all duration-300 cursor-pointer">
                    View Project
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="max-w-screen-2xl mx-auto bg-fit lg:hidden"
        style={{
          backgroundImage: `url('${portfolioImg}')`,
        }}
      >
        <div className="text-white py-20 px-4 sm:px-20 flex flex-col gap-4 justify-between w-full lg:mb-10">
          <div>
            <h2 className="text-3xl font-extrabold font-montserrat text-white sm:mb-10 sm:text-5xl">
              Portfolio
            </h2>
            <div className="flex w-full flex-col justify-between sm:items-end sm:flex-row gap-10">
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                    42
                  </h2>
                  <p className="text-sm">Completed projects</p>
                </div>
                <div className="flex items-center gap-2">
                  <h2 className="text-3xl font-extrabold font-gtAmerica text-white mb-4 sm:mb-8 sm:text-5xl">
                    30+
                  </h2>
                  <p className="text-sm">Happy clients</p>
                </div>
              </div>
              <p className="text-xl mb-10 font-semibold sm:w-[350px]">
                Our works are a blend of innovative thinking and practical
                solutions, ensuring they are both unique and effective.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            className="flex justify-center items-center "
            pagination={{
              type: "progressbar",
              el: ".pagination-progress",
            }}
            autoplay={true}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            loop
          >
            {" "}
            {project.map((item, index) => (
              <SwiperSlide className="!flex justify-center z-10" key={index}>
                <div className="rounded-3xl">
                  <a href={item.link} target="_blank">
                    <img className="w-full object-cover" src={item.icon} alt= {item.alt} />
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
