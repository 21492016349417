import React from 'react'
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import heroimg from "../../assets/images/whowearehero.webp"
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const AboutPageHeroSection = () => {
  const navigate = useNavigate()
  return (
    <div className="max-w-screen-2xl mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row lg:gap-10 justify-between items-center">
      <div className="lg:w-[50%]">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, type: "spring", stiffness: 50 }}
          className="bg-primary flex justify-center items-center p-2 size-24 rounded-full mb-10 relative z-10 cursor-pointer"
          onClick={() =>
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            })
          }
        >
          <img src={arrow} alt="" className="rotate-90" />
        </motion.div>
        <motion.h1
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 50,
            delay: 0.5,
          }}
          className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
        >
          Something we gonna tell you{" "}
          <span className="text-secondary">about us</span>
        </motion.h1>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 50,
            delay: 1,
          }}
        >
          <div onClick={() => navigate("/contact")}>
            <PrimaryButton
              icon={<img src={arrow} alt="arrow" />}
              className="relative bg-primary z-10"
            >
              Get in touch
            </PrimaryButton>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ x: 100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="lg:w-[50%] mb-4 lg:mb-0"
      >
        <img src={heroimg} alt="" className="w-full" />
      </motion.div>
    </div>
  );
}

export default AboutPageHeroSection