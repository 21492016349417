import React from "react";
import {
  client1,
  client3,
  client4,
  client5,
  client6,
  client7,
} from "../../assets/images/index";
import Marquee from "react-fast-marquee";

const items: string[] = [
  client1,
  client3,
  client4,
  client5,
  client6,
  client7,
];

const Clients = () => {
  // Duplicate items for seamless scrolling
  const duplicatedItems = [...items, ...items, ...items, ...items, ...items, ...items];

  return (
    <div className="py-5 sm:py-4 border-y-[1px] border-black border-opacity-10">
      <div className="mx-auto max-w-screen-2xl w-full flex justify-center items-center">
        <Marquee
          className="flex justify-center items-center"
          speed={50} // Speed of the scrolling
          gradient={false} // Disables the gradient at the edges
          pauseOnHover={true} // Pauses the scroll when hovered
          direction="left" // Scroll direction
        >
          {duplicatedItems.map((item, index) => (
            <div className="flex justify-center mx-6 flex-shrink-0" key={index}>
              <img
                className="w-40 h-32 sm:h-40 object-contain rounded-lg"
                src={item}
                alt={`Client ${index + 1}`}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Clients;
