import React, { useState } from "react";
import {
  designhero1,
  designhero2,
  designhero3,
  designhero4,
  designhero5,
  designhero6,
  designhero7,
  designhero8,
  designhero9,
  designhero10,
} from "../../../assets/images/index";
import arrow from "../../../assets/images/arrow.svg";
import PrimaryButton from "../../common/PrimaryButton";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const DesignHeroSection = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className=" w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
        <div className="lg:w-[50%]">
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" top-32 sm:top-40 left-8 sm:left-20 xl:left-28 z-0"
          >
            <motion.h2 className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6">
              Design
            </motion.h2>
            <p className="font-montserrat font-semibold text-lg sm:text-xl mb-4 sm:mb-6 w-4/5">
              Elevate your brand with Techqilla’s expert design services.
              Creativity that connects!{" "}
            </p>
            <PrimaryButton
              className="text-white border border-white bg-primary  sm:w-auto text-sm items-center relative z-10"
              icon={<img src={arrow} alt="arrow" className="" />}
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Talk about a project
            </PrimaryButton>
          </motion.div>
        </div>
        <motion.div className="flex gap-2 lg:gap-8 lg:w-[60%] mb-4 lg:mb-0">
          <div className="h-[60%] w-[25%] self-end">
            <div className="h-[65%]">
              <motion.img
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                src={designhero1}
                alt=""
                className="h-[50%]"
              />
              <motion.img
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                src={designhero4}
                alt=""
                className="h-[50%] w-full"
              />
            </div>
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              src={designhero5}
              alt=""
              className="h-[35%] w-full"
            />
          </div>
          <div className="w-[25%] flex flex-col justify-between">
            <motion.img
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={designhero2}
              alt=""
              className=""
            />
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={designhero6}
              alt=""
              className=""
            />
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              src={designhero7}
              alt=""
              className=""
            />
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              src={designhero8}
              alt=""
              className=""
            />
          </div>
          <div className="w-[50%]">
            <motion.img
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              src={designhero3}
              alt=""
              className="h-[70%] w-full"
            />
            <div className="h-[30%] w-full flex overflow-hidden">
              <motion.img
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                src={designhero9}
                alt=""
                className="w-[35%]"
              />
              <motion.img
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                src={designhero10}
                alt=""
                className="w-[65%]"
              />
            </div>
          </div>
        </motion.div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="design"
          />
        </div>
      )}
    </>
  );
};

export default DesignHeroSection;
