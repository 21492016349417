import React from "react";
import tick from "../../../../assets/svgs/tick.svg"
type TableRow = {
  task: string;
};

type TableProps = {
  rows: TableRow[]; // Array of table rows
  bgColor?: string; // Background color (optional)
  headerColor?: string; // Header color (optional)
  className?: string; // Additional custom classes (optional)
  text1: string
};
const Task: React.FC<TableProps> = ({
  rows,
  bgColor , // Default background color
  headerColor , // Default header color
  className = "",
  text1
}) => {
  return (
    <div className={`${bgColor} text-white ${className}`}>
      <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-20">
        <p
          className="font-gtAmerica text-lg mb-9"
          dangerouslySetInnerHTML={{ __html: text1 }}
        ></p>
        <h2 className="text-4xl font-montserrat font-bold mb-6">
          Tasks we got in this project
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            {/* Table Header */}
            <thead className="">
              <tr>
                <th
                  className={`py-3 px-4 text-center ${headerColor} text-white font-gtAmerica text-2xl font-normal`}
                >
                  Task List
                </th>
                <th
                  className={`py-3 px-4 text-center ${headerColor} text-white text-2xl font-gtAmerica font-normal`}
                >
                  Status Done
                </th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {rows.map((row, index) => (
                <tr key={index} className="border-t border-gray-300">
                  <td className="py-3 px-4 border border-gray-300 text-center font-gtAmerica text-2xl
                  ">
                    {row.task}
                  </td>
                  <td className="py-3 px-4 text-center flex justify-center">
                    <img src={tick} className="" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="mt-6 text-lg">
          Our job was to create website design & development, Social media
          designing, posting, and Lead generation.
        </p>
      </div>
    </div>
  );
};

export default Task