import React, { useState, useEffect } from "react";
import { fetchBlogs, fetchCategories } from "../../service/service";
import Blog from "./Blog";
import { BlogCategory, BlogType } from "../../types/types";

const Blogs = () => {
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [blogs, setBlogs] = useState<BlogType[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<BlogType[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("All");

  useEffect(() => {
    (async () => {
      const blogsResponse = await fetchBlogs();
      if (blogsResponse.status === 200) {
        setBlogs(blogsResponse.blogs as BlogType[]);
        setFilteredBlogs(blogsResponse.blogs as BlogType[]);
      }

      const categoriesResponse = await fetchCategories();
      if (categoriesResponse.status === 200) {
        setCategories([
          { name: "All" }, // Default "All" category
          ...(categoriesResponse.categories as BlogCategory[]),
        ]);
      }
    })();
  }, []);

  const handleCategoryChange = (categoryName: string) => {
    setActiveCategory(categoryName);
    if (categoryName === "All") {
      setFilteredBlogs(blogs);
    } else {
      setFilteredBlogs(
        blogs.filter((blog) =>
          blog.techqilla_categories.some(
            (category) => category.name === categoryName
          )
        )
      );
    }
  };

  return (
    <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
      <div className="w-full mx-auto mt-6 sm:mt-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row gap-10 justify-between">
        <div className="lg:w-[65%] grid lg:grid-cols-2 gap-10">
          {filteredBlogs.slice(0, 2).map((blog, index) => (
            <Blog key={index} blog={blog} />
          ))}
        </div>
        <div className="lg:w-[35%]">
          <h3 className="mb-5 text-2xl font-montserrat font-semibold">
            Categories
          </h3>
          <div className="flex flex-wrap gap-2 mb-5">
            {categories.map((category) => (
              <button
                onClick={() => handleCategoryChange(category.name)}
                key={category.name}
                className={`py-2 px-4 border-2 border-primary text-black relative z-10 ${
                  activeCategory === category.name
                    ? "bg-primary text-white"
                    : "text-primary hover:bg-gray-200"
                } rounded-3xl font-semibold font-montserrat transition-colors`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full mx-auto my-6 sm:pb-20 sm:mt-14 px-4 sm:px-6 lg:px-8 grid lg:grid-cols-3 gap-10 justify-between">
        {filteredBlogs.slice(2, 5).map((blog, index) => (
          <Blog key={index} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default Blogs;
