import React from "react";
import {
  mongodbIcon,
  expressIcon,
  React1Icon,
  nodeIcon,
  angularIcon,
  flutterIcon,
  java1Icon,
  sqlIcon,
  dotnetIcon,
} from "../../../assets/icons/index";
import {
  softwareswiper1,
  softwareswiper2,
  softwareswiper3,
  softwareicon1,
  softwareicon2,
  softwareicon3,
  softwareswiper4,
} from "../../../assets/images/index";
import SoftwareDevelopmentHeroSction from "./SoftwareDevelopmentHeroSction";
import GridComponent from "../../common/GridComponent";
import Slider from "../../common/Slider";
import SkillComponent from "../../common/SkillComponent";
import { ServiceType } from "../../../types/types";

type ListType = {
  icon: string;
  title: string;
  description: string;
  serviceType: ServiceType;
}

const SoftwareDevelopmentPage = () => {
  const griditems: ListType[] = [
    {
      icon: softwareicon1,
      title: "CRM Development",
      serviceType: "software-dev",
      description:
        "Unlock the power of personalized customer relationships with Techqilla’s CRM development. Custom-built solutions to help you engage, manage, and grow your business.",
    },
    {
      icon: softwareicon2,
      title: "Website application",
      serviceType: "software-dev",
      description:
        "Revolutionize your business with Techqilla’s website applications—custom solutions that drive results and user engagement.",
    },
    {
      icon: softwareicon3,
      serviceType: "software-dev",
      title: "Mobile development",
      description:
        "Stay ahead with Techqilla’s mobile development. We craft apps that engage users, simplify processes, and help your business grow.",
    },
  ];

  const swiperitems = [
    {
      name: softwareswiper1,
      link: "",
    },
    {
      name: softwareswiper2,
      link: "",
    },
    {
      name: softwareswiper3,
      link: "",
    },
    {
      name: softwareswiper4,
      link: "",
    },
  ];

  const softwareskill = [
    {
      icon: mongodbIcon,
      name: "Mongo DB",
    },
    {
      icon: expressIcon,
      name: "Express JS",
    },
    {
      icon: React1Icon,
      name: "React",
    },
    {
      icon: nodeIcon,
      name: "Node JS",
    },
    {
      icon: angularIcon,
      name: "Angular",
    },
    {
      icon: React1Icon,
      name: "React Native",
    },
    {
      icon: flutterIcon,
      name: "Flutter",
    },
    {
      icon: java1Icon,
      name: "Java",
    },

    {
      icon: sqlIcon,
      name: "SQL",
    },
    {
      icon: dotnetIcon,
      name: ".Net",
    },
  ];

  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <SoftwareDevelopmentHeroSction />
      </div>
      <GridComponent heading="" list={griditems} />
      <Slider
        heading="Softwares"
        items={swiperitems}
        buttonData={{
          name: "Start a Project with us",
          className: "relative z-10 bg-primary text-white",
        }}
      />
      <SkillComponent
        heading="Software Designing Tools"
        items={softwareskill}
      />
    </>
  );
};

export default SoftwareDevelopmentPage;
