import React from 'react'

type ResultType = {
  heading: string
  text: string
  cards: CardProps[]
  cardColor: string
}

type CardProps = {
  icon: string
  subheading: string
  text: string
  
}

const Result = ({heading, text, cards, cardColor}:ResultType) => {
  return (
    <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-20">
      <div>
        <h1 className="font-montserrat font-bold text-3xl mb-2">{heading}</h1>
        <p className="mb-4 lg:mb-9">{text}</p>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-10'>
          {cards.map((item, index) => (
            <div className={`${cardColor} py-14 px-10 rounded-2xl`} key={index}>
              <div className='flex flex-col justify-between items-center gap-10'>
                <img src={item.icon} className='' alt='' />
                <h3 className='font-gtAmerica font-2xl text-white font-semibold'>{item.subheading}</h3>
                <p className='font-montserrat text-white'>{ item.text}</p>
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Result