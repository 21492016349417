import React, { useState } from "react";
import {
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
} from "../../../assets/images/index";
import arrow from "../../../assets/images/arrow.svg";
import PrimaryButton from "../../common/PrimaryButton";
import { motion } from "framer-motion";
import ContactPopup from "../../common/ContactPopup";

const LogoDesing = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        id="logo-design"
        className=" border-t border-b border-black py-6 sm:py-20 px-4 sm:px-6 lg:px-8  lg:flex justify-between "
      >
        <div className="lg:w-[30%] flex flex-col justify-center ">
          <motion.h3
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-extrabold font-montserrat text-gray-900 mb-4 sm:mb-8 sm:text-5xl"
          >
            Logo Design
          </motion.h3>
          <motion.p
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="font-montserrat text-lg font-medium mb-4"
          >
            Make a lasting impression with a custom logo design from Techqilla.
            Let's create yours!
          </motion.p>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            <PrimaryButton
              className="self-start px-3 sm:px-6 text-sm  xl:text-base bg-primary relative z-10"
              icon={<img src={arrow} alt="arrow" />}
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Start a project with us
            </PrimaryButton>
          </motion.div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-6 sm:w-[70%]">
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="col-span-1"
          >
            <img src={logo1} alt="Image 1" className="rounded-lg" />
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="col-span-1"
          >
            <img src={logo2} alt="Image 2" className="rounded-lg" />
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 2 }}
            className="col-span-1 sm:row-span-2"
          >
            <img src={logo5} alt="Image 3" className="rounded-lg" />
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 }}
            className="col-span-1"
          >
            <img src={logo4} alt="Image 4" className="rounded-lg" />
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1.5 }}
            className="col-span-1"
          >
            <img src={logo3} alt="Image 5" className="rounded-lg" />
          </motion.div>
        </div>
      </div>
      {modalIsOpen && (
        <div className="absolute h-screen w-screen flex justify-center items-center">
          <ContactPopup
            isOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            service="design"
          />
        </div>
      )}
    </>
  );
};

export default LogoDesing;
