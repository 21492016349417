import React from "react";
import BusinessPageHeroSection from "./BusinessPageHeroSection";
import GridComponent from "../../common/GridComponent";
import {
  businessicon1,
  businessicon2,
  businessicon3,
  businessicon4,
} from "../../../assets/images/index";
import { ServiceType } from "../../../types/types";

type ListType = {
  title: string;
  description: string;
  icon: string;
  serviceType: ServiceType;
};

const BusinessConsultancyPage = () => {
  const icons: ListType[] = [
    {
      icon: businessicon1,
      title: "Strategy Building",
      serviceType: "business-consult",
      description:
        "Techqilla specializes in building robust, growth-focused strategies. We craft unique, actionable plans based on deep market insights to drive success, optimize processes, and position your business as an industry leader for lasting, sustainable impact.",
    },
    {
      icon: businessicon2,
      title: "Lead Generation",
      serviceType: "business-consult",
      description:
        "Supercharge your sales with Techqilla’s lead generation expertise. We design personalized campaigns that attract qualified leads, nurture relationships, and drive conversions, ensuring a steady stream of prospects that contribute to your business’s success",
    },
    {
      icon: businessicon3,
      title: "LinkedIn Sales Navigator Training",
      serviceType: "business-consult",
      description:
        "Master LinkedIn Sales Navigator with Techqilla’s training. We guide you through building strong prospect lists, engaging effectively, and closing more deals. Maximize your sales potential and turn LinkedIn into a lead-generation powerhouse for your business.",
    },
    {
      icon: businessicon4,
      title: "Recruiting",
      serviceType: "business-consult",
      description:
        "Find the right talent faster with Techqilla’s recruiting services. We utilize advanced strategies to identify, screen, and connect you with top professionals who align with your company’s vision, ensuring the best hires for long-term success.",
    },
  ];
  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <BusinessPageHeroSection />
      </div>
      <GridComponent heading="" list={icons}></GridComponent>
    </>
  );
};

export default BusinessConsultancyPage;
