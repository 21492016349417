import React from "react";
import { Field } from "formik";
interface FormikFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  placeholder?: string;
}
const EditorTextArea = ({
  name,
  label,
  placeholder,
  ...props
}: FormikFieldProps) => {
  return (
    <div className="mb-4 poppins-regular">
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-white font-montserrat"
        >
          {label}
        </label>
      )}
      <div className="p-[1px] custom-gradient2 rounded-lg">
        <Field
          id={name}
          name={name}
          as="textarea"
          placeholder={placeholder}
          className="w-full px-4 py-3 h-32  rounded-lg shadow-sm font-montserrat focus:ring-0 active:ring-0 focus:outline-none"
          {...props}
        />
      </div>
    </div>
  );
};
export default EditorTextArea;