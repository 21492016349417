import React from "react";
import CareerHeroSection from "./CareerHeroSection";
import Position from "./Position";

const CareerPage = () => {
  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <CareerHeroSection />
        <Position />
      </div>
    </>
  );
};

export default CareerPage;
