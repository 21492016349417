import React from 'react'
import { Link } from 'react-router-dom';

type Projects = {
project: ProjectProps[]
}

type ProjectProps = {
  img: string;
  name: string;
  link: string;
}

const OtherProject = ({project}:Projects) => {
  return (
    <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-20">
      <div className="w-full flex justify-center mb-5 lg:mb-10">
        <h2 className="montserrat-bold text-4xl">Another Project</h2>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row justify-center gap-5 lg:gap-28">
          {project.map((item, index) => (
            <Link to={item.link}>
              <div key={index} className="flex items-center gap-4">
                <img className="" src={item.img} alt="" />
                <p className="montserrat-bold text-2xl ">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OtherProject