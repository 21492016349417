import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Navigation } from "swiper/modules";
import img1 from "../../assets/images/cultureImg1.png";
import img2 from "../../assets/images/cultureImg2.png";
import img3 from "../../assets/images/cultureImg3.png";
import img4 from "../../assets/images/cultureImg4.png";
import img5 from "../../assets/images/cultureImg5.png";
import img6 from "../../assets/images/cultureImg6.png";
import img7 from "../../assets/images/cultureImg7.png";
import img8 from "../../assets/images/cultureimg8.png";
import img9 from "../../assets/images/cultureimg9.png";
import img10 from "../../assets/images/cultureimg10.png";
import video1 from "../../assets/video/video1.mp4";
import video2 from "../../assets/video/video2.mp4";
import video3 from "../../assets/video/video3.mp4";
import { FaArrowRightLong } from "react-icons/fa6";

type SwiperType = {
  type: string;
  src: string;
};

const swipers: SwiperType[] = [
  {
    type: "image",
    src: img1,
  },
  {
    type: "image",
    src: img2,
  },
  {
    type: "image",
    src: img3,
  },
  {
    type: "image",
    src: img4,
  },
  {
    type: "image",
    src: img5,
  },
  {
    type: "image",
    src: img6,
  },
  {
    type: "image",
    src: img7,
  },
  {
    type: "image",
    src: img8,
  },
  {
    type: "image",
    src: img9,
  },
  {
    type: "image",
    src: img10,
  },
  {
    type: "video",
    src: video1,
  },
  {
    type: "video",
    src: video2,
  },
  {
    type: "video",
    src: video3,
  },
];

const Culture = () => {
  return (
    <div className="py-20 px-4 sm:px-20 flex flex-col items-center gap-4 justify-between w-full">
      <div className="flex flex-col font-gtAmerica md:flex-row items-center justify-center mx-auto px-4 sm:px-10 py-6 sm:py-20">
        <div className="md:w-1/3 text-left md:pr-8">
          <h2 className="text-3xl mb-4 font-extrabold font-montserrat sm:mb-10 sm:text-5xl text-white">
            Our culture <br /> runs deep
          </h2>
        </div>
        <div className="w-px bg-white sm:h-40 mx-8"></div>
        <div className="md:w-1/2 text-left">
          <p className="text-lg font-bold sm:text-3xl mb-4 text-white">
            We deliver the know-how needed to successfully navigate and thrive
            in the Creator Economy, matching the particular requirements of your
            agency or product.
          </p>
          <p className="text-sm text-white">
            Techqilla Agency designed the website of a retail technology firm
            based on their new brand identity. They created lo-fi UX wireframes,
            produced hi-fi design mock-ups, and prepared all assets for the
            handover.
          </p>
        </div>
      </div>
      <div className="flex justify-end mb-5 lg:mr-10 w-full">
        <div className="flex gap-2 relative z-10">
          <div className="swiper-prev p-4 bg-primary hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-white cursor-pointer">
            <FaArrowRightLong className="text-white rotate-180" />
          </div>

          <div className="swiper-next p-4 bg-primary hover:bg-white [&>*]:hover:text-black transition-custom rounded-full border border-white cursor-pointer">
            <FaArrowRightLong className="text-white" />
          </div>
        </div>
      </div>
      <div className="max-w-screen-2xl [&>.swiper>.swiper-wrapper]:items-center w-full flex justify-center items-center">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          loop={true}
          className="flex justify-center items-center"
        >
          {swipers.map((media, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center items-center"
            >
              {media.type === "image" ? (
                <img
                  src={media.src}
                  alt=""
                  className="w-full h-auto object-cover"
                />
              ) : (
                <video
                  className="w-full h-auto object-cover"
                  controls
                  preload="metadata"
                >
                  <source src={media.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Culture;
