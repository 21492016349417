import React from "react";
import AboutUs from "./AboutUs";
import Expertise from "./Expertise";
import Hero from "./Hero";
import JoinUs from "./JoinUs";
import Portfolio from "./Portfolio";
import Reviews from "./Reviews";
import Services from "./Services";
import Clients from "./Clients";
import Creators from "../AboutPage/Creators";

const HomePage = () => {
  return (
    <div className="overflow-hidden">
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <Hero />
        <Clients />
        <AboutUs />
      </div>
      <Services />
      <div className="mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <Expertise />
      </div>
      <Portfolio />
      <Reviews />
      <JoinUs />
      <Creators />
    </div>
  );
};

export default HomePage;
