import React from "react";
import site from "../../../assets/svgs/site.svg";
import front from "../../../assets/svgs/frony.svg";
import back from "../../../assets/svgs/back.svg";
import prototype from "../../../assets/svgs/prototype.svg";

type ProgressProps = {
  icon: string;
  title: string;
  des: string;
};

const progress: ProgressProps[] = [
  {
    icon: site,
    title: "Site Map",
    des: "Now that your ideal clients are on your website, you need to keep them there. A site map organizes the website pages, so your client keeps clicking. Think of it as a map to help your client reach their destination: buying your service. Don’t let them get lost.",
  },
  {
    icon: front,
    title: "Front-End Development",
    des: "Using creativity and a well-executed strategy, we produce a website as unique as you and your brand! We carefully craft your website with clean design elements while also considering the user experience.",
  },
  {
    icon: back,
    title: "Backend Development",
    des: "Coding is our second language! Our fluency in coding lets us fluidly work on your backend services and inputting all the necessary website features, so you don’t need to struggle.",
  },
  {
    icon: prototype,
    title: "Prototype & Launch",
    des: "The prototype gives you the first glimpse of what your working site will look like! We publish your website on a test server while it is still a work-in-progress to ensure it’s functioning correctly and every detail fits your vision before you officially launch.",
  },
];

const SiteSVG = ({ path1Cls }: { path1Cls: string }) => (
  <svg
    width="60"
    height="51"
    viewBox="0 0 60 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={path1Cls}
      d="M28.1047 34.7961L39.5222 23.3786L40.9203 24.7766L28.8037 37.0097H27.4057L20.8814 30.3689L22.2795 28.8543L28.1047 34.7961ZM49.5416 0.660137V4.38829H59.794V50.9902H0.143555V4.38829H10.396V0.660137H17.8523V4.38829H42.0853V0.660137H49.5416ZM43.9494 2.52422V8.11645H47.6775V2.52422H43.9494ZM12.2601 2.52422V8.11645H15.9882V2.52422H12.2601ZM2.00763 49.1262H57.93V13.7087H2.00763V49.1262ZM57.93 6.25237H49.5416V9.98053H42.0853V6.25237H17.8523V9.98053H10.396V6.25237H2.00763V11.8446H57.93V6.25237Z"
      fill="#F20B97"
    />
  </svg>
);

const FrontSVG = ({ path1Cls }: { path1Cls: string }) => (
  <svg
    width="33"
    height="61"
    viewBox="0 0 33 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={path1Cls}
      d="M16.2793 0.0859375C25.0684 0.0859375 32.2168 7.23438 32.2168 16.0234V44.1484C32.2168 52.9375 25.0684 60.0859 16.2793 60.0859C7.49023 60.0859 0.341797 52.9375 0.341797 44.1484V16.0234C0.341797 7.23438 7.49023 0.0859375 16.2793 0.0859375ZM15.3418 1.96094C8.07617 2.54688 2.2168 8.64062 2.2168 16.0234V18.8359H15.3418V1.96094ZM16.2793 58.2109C24.0137 58.2109 30.3418 51.8828 30.3418 44.1484V20.7109H2.2168V44.1484C2.2168 51.8828 8.54492 58.2109 16.2793 58.2109ZM30.3418 18.8359V16.0234C30.3418 8.64062 24.4824 2.54688 17.2168 1.96094V18.8359H30.3418Z"
      fill="#F20B97"
    />
  </svg>
);
const BackSVG = ({ path1Cls }: { path1Cls: string }) => (
  <svg
    width="61"
    height="38"
    viewBox="0 0 61 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={path1Cls}
      d="M30.8086 0.199947L61.1343 13.3295L46.6105 19.139L61.1343 25.4133L30.8086 37.6133L0.48291 25.4133L15.0067 19.139L0.48291 13.3295L30.8086 0.199947ZM56.2543 25.4133L44.1705 20.1847L30.8086 25.5295L17.4467 20.1847L5.36291 25.4133L30.8086 35.5219L56.2543 25.4133ZM44.1705 18.0933L56.2543 13.3295L30.8086 2.29137L5.36291 13.3295L17.4467 18.0933L30.8086 23.438L44.1705 18.0933Z"
      fill="#F20B97"
    />
  </svg>
);
const PrototypeSVG = ({ path1Cls }: { path1Cls: string }) => (
  <svg
    width="61"
    height="40"
    viewBox="0 0 61 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={path1Cls}
      d="M53.6367 0.398438V32.2734H60.1992V39.7734H0.199219V32.2734H6.76172V0.398438H53.6367ZM8.63672 2.27344V32.2734H51.7617V2.27344H8.63672ZM58.3242 34.1484H2.07422V37.8984H58.3242V34.1484ZM28.3242 4.14844V6.02344H32.0742V4.14844H28.3242Z"
      fill="#F20B97"
    />
  </svg>
);
const WorkProgress = () => {
  return (
    <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-28">
      <h2 className="font-montserrat font-extrabold text-3xl sm:text-5xl xl:text-6xl mb-10">
        Work Process
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
        <div
          className="relative z-10 py-8 px-5 border rounded-2xl border-primary hover:bg-primary hover:text-white transition-all duration-300 group"
        >
          <div className="flex flex-col items-center gap-5 h-full">
            <SiteSVG path1Cls="group-hover:fill-white"/>
            <h3 className="font-montserrat font-semibold text-center h-[48px] text-2xl">
              Site Map
            </h3>
            <p className="font-montserrat text-center">Now that your ideal clients are on your website, you need to keep them there. A site map organizes the website pages, so your client keeps clicking. Think of it as a map to help your client reach their destination: buying your service. Don’t let them get lost.</p>
          </div>
        </div>
        <div
          className="relative z-10 py-8 px-5 border rounded-2xl border-primary hover:bg-primary hover:text-white transition-all duration-300 group"
        >
          <div className="flex flex-col items-center gap-5 h-full">
            <FrontSVG path1Cls="group-hover:fill-white" />
            <h3 className="font-montserrat font-semibold text-center h-[48px] text-2xl">
              Front End Development
            </h3>
            <p className="font-montserrat text-center">Using creativity and a well-executed strategy, we produce a website as unique as you and your brand! We carefully craft your website with clean design elements while also considering the user experience.</p>
          </div>
        </div>
        <div
          className="relative z-10 py-8 px-5 border rounded-2xl border-primary hover:bg-primary hover:text-white transition-all duration-300 group"
        >
          <div className="flex flex-col items-center gap-5 h-full">
            <BackSVG path1Cls="group-hover:fill-white" />
            <h3 className="font-montserrat font-semibold text-center h-[48px] text-2xl">
              Back End Development
            </h3>
            <p className="font-montserrat text-center">Coding is our second language! Our fluency in coding lets us fluidly work on your backend services and inputting all the necessary website features, so you don’t need to struggle.</p>
          </div>
        </div>
        <div
          className="relative z-10 py-8 px-5 border rounded-2xl border-primary hover:bg-primary hover:text-white transition-all duration-300 group"
        >
          <div className="flex flex-col items-center gap-5 h-full">
            <PrototypeSVG path1Cls="group-hover:fill-white" />
            <h3 className="font-montserrat font-semibold text-center h-[48px] text-2xl">
            Prototype & Launch
            </h3>
            <p className="font-montserrat text-center">The prototype gives you the first glimpse of what your working site will look like! We publish your website on a test server while it is still a work-in-progress to ensure it’s functioning correctly and every detail fits your vision before you officially launch.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkProgress;
