import React, { useState } from "react";
import ContactHeroSection from "./ContactHeroSection";

const Contact = () => {
  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <ContactHeroSection />
      </div>
    </>
  );
};

export default Contact;
