import React from "react";
import { motion } from "framer-motion";
import arrow from "../../assets/images/arrow3.svg"
import { useNavigate } from "react-router-dom";

import {
  figmaIcon,
  adobeIllustratorIcon,
  photoShopIcon,
  goIcon,
  javaIcon,
  phpIcon,
  reactIcon,
  cssIcon,
  swiftIcon,
  htmlIcon,
  samrusIcon,
  analyticsIcon,
  tagManagerIcon,
} from "../../assets/icons";
import PrimaryButton from "../common/PrimaryButton";

type ServiceType = {
  name: string;
  description: string;
  icons: JSX.Element[];
};

const services: ServiceType[] = [
  {
    name: "Designing",
    description:
      "Designs that speak louder than words—captivating, memorable, and uniquely yours.",
    icons: [
      <img src={figmaIcon} alt="figma" />,
      <img src={adobeIllustratorIcon} alt="adobeIllustrator" />,
      <img src={photoShopIcon} alt="photoShop" />,
    ],
  },

  {
    name: "Website & App Development",
    description:
      "Building seamless websites and apps that combine functionality, innovation, and user experience to drive growth.",
    icons: [
      <img src={reactIcon} alt="reactIcon" />,
      <img src={cssIcon} alt="cssIcon" />,
      <img src={swiftIcon} alt="swiftIcon" />,
      <img src={htmlIcon} alt="htmlIcon" />,
    ],
  },

  {
    name: "Software Development",
    description:
      "Innovative software solutions designed to optimize workflows, enhance efficiency, and drive growth.",
    icons: [
      <img src={javaIcon} alt="javaIcon" />,
      <img src={goIcon} alt="goIcon" />,
      <img src={phpIcon} alt="phpIcon" />,
    ],
  },

  {
    name: "Marketing",
    description:
      "Strategic marketing solutions that drive engagement, build your brand, and deliver measurable results",
    icons: [
      <img
        src={samrusIcon}
        className="bg-orange-600 rounded-md"
        alt="samrusIcon"
      />,
      <img src={analyticsIcon} alt="analyticsIcon" />,
      <img src={tagManagerIcon} alt="tagManagerIcon" />,
    ],
  },
];

const Services = () => {
  const navigate = useNavigate()
  return (
    <div className="py-20 flex justify-center w-full px-5 sm:px-20 bg-primary text-white relative">
      <div className="max-w-screen-2xl w-full px-4 sm:px-8">
        <motion.h2
          initial={{ y: -200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="text-3xl font-extrabold font-montserrat px-5 sm:px-0 text-left mb-4 sm:mb-8 sm:text-5xl"
        >
          Services
        </motion.h2>
        <hr className="w-full border-1 border-white my-10" />
        <div className="grid md:grid-cols-3 max-w-screen-2xl font-gtAmerica mx-auto gap-10 mt-16 mb-4">
          {services.map((service, index) => (
            <div key={index} className="flex gap-4 items-start flex-col ">
              <div>
                <motion.h3
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    stiffness: 50,
                    delay: index * 0.2,
                  }}
                  className="font-semibold text-xl"
                >
                  {service.name}
                </motion.h3>
                <motion.p
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    stiffness: 50,
                    delay: index * 0.2,
                  }}
                  className="mt-1 text-white"
                >
                  {service.description}
                </motion.p>
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    type: "spring",
                    stiffness: 50,
                    delay: index * 0.2,
                  }}
                  className="flex gap-2 my-4"
                >
                  {service.icons.map((icon, index) => (
                    <div
                      key={index}
                      className="p-2 bg-white rounded-full hover:-translate-y-1 transition-all duration-300 cursor-pointer"
                    >
                      {icon}
                    </div>
                  ))}
                </motion.div>
              </div>
            </div>
          ))}
        </div>
        <div onClick={() => navigate("/case-study")}>
          <PrimaryButton
            icon={<img src={arrow} alt="arrow" className="relative z-10" />}
            className="relative bg-white !text-black"
          >
            View Our Work
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Services;
