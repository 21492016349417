import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrow from '../../assets/blogs/arrow.webp';
import { BlogType } from '../../types/types';
import { format } from "date-fns";

type BlogProps = {
  blog: BlogType
}


const Blog = ({ blog }: BlogProps) => {
  const navigate = useNavigate();

  let url = "https://admin.johnbostjancic.co";
  if (process.env.NODE_ENV === "development") {
    url = "https://admin.johnbostjancic.co";
  }

  return (
    <div
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        navigate(`/blog/${blog.slug}`, { replace: true });
      }}
      className="flex flex-col"
    >
      <div className="mb-6">
        <img src={`${url}${blog.cover.url}`} alt="" className="w-full h-[200px]" />
      </div>
      <div className="flex justify-between text-lg font-semibold font-gtAmerica mb-5">
        <p>{blog.title}</p>
        <div className="size-8 flex justify-center items-center bg-secondary rounded-full">
          <img src={arrow} alt="" className="" />
        </div>
      </div>
      <p className="opacity-50 font-gtAmerica mb-4">{blog.description}</p>
      <div className="flex justify-between mb-4">
        <div className="flex gap-4">
          <img src={`${url}${blog.techqilla_authors[0].image}`} alt={blog.techqilla_authors[0].name} className='size-8 rounded-full'  />
          <p className="sm:text-lg font-gtAmerica font-semibold">
            {blog.techqilla_authors.map((author) => author.name).join(", ")}
          </p>
        </div>
        <p className="sm:text-lg font-gtAmerica font-semibold">
          {format(blog.createdAt, "MMM dd, yyyy")}
        </p>
      </div>
      <div className="flex gap-5">
        {blog.techqilla_categories.map((cat,index) => (
          <div key={index} className="py-2 px-4 border-2 border-black rounded-3xl font-gtAmerica font-semibold">
            {cat.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog