import React from 'react'
import icon from "../../../../assets/svgs/listsvg.svg"
type List = {
  subheading: string
  des: string
}

type Task1 = {
  heading: string;
  text: string
  list: List[]
  listcolor: string
  listStyleColor:string
}

const Task1 = ({heading, text, list, listcolor, listStyleColor}:Task1) => {
  return (
    <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-8 lg:py-20">
      <div className="">
        <h1 className="text-3xl font-bold font-montserrat mb-2">{heading}</h1>
        <p className="font-gtAmerica text-lg mb-7">{text}</p>
        <ul>
          {list.map((item, index) => (
            <div className="flex items-center gap-4">
              <span>
                <svg
                  width="33"
                  height="17"
                  viewBox="0 0 33 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0H26L33 8.5L26 17H0L7 8.5L0 0Z" className={`${listStyleColor}`} />
                </svg>
              </span>{" "}
              <li className="text-lg font-gtAmerica">
                <span className={`font-bold ${listcolor} `}>
                  {item.subheading}
                </span>
                {item.des}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Task1