import React from "react";
import { motion } from "framer-motion";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import img from "../../assets/images/careerhero.webp";
const CareerHeroSection = () => {
  return (
    <div className="w-full mx-auto py-6 sm:py-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:flex-row justify-between items-center">
      <div className="lg:w-[40%]">
        <motion.h1
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
          className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
        >
          Get hired and take the next step in your career
        </motion.h1>
        <motion.p
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 200,
            delay: 0.5,
          }}
          className="font-montserrat font-semibold text-lg sm:text-xl mb-4 sm:mb-6 w-4/5"
        >
          Kickstart your journey with Techqilla! Join our team and shape the
          future of innovation.
        </motion.p>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 200,
            delay: 1,
          }}
        ></motion.div>
      </div>
      <div className="lg:w-[60%] mb-4 lg:mb-0">
        <motion.img
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          src={img}
          alt="hero image"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default CareerHeroSection;
