import React, { useState } from "react";
import pero from "../../assets/images/expertise1.svg"
import tanks from "../../assets/images/expertise2.svg"
import lone from "../../assets/images/expertise3.svg"
import softside from "../../assets/images/expertise4.svg"
import psyethos from "../../assets/images/expertise5.svg";
import viti from "../../assets/images/expertise6.svg";
import { Link } from "react-router-dom";

type ExpertiseType = {
  heading?: string;
  des: string;
  logo: string;
  alt: string;
  link: string;
};

const experties: { [key: string]: ExpertiseType } = {
  Automative: {
    heading: "Automotive",
    des: "Driving brands forward with targeted marketing strategies that accelerate growth in the automotive industry.",
    logo: viti,
    alt: "vitti",
    link: "https://vittilog.com/",
  },
  Finance: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Media_and_Entertainment: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Public_Sector: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Sports: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Blockchain_and_crypto: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Hospitality: {
    heading: "Hospitality",
    des: "Enhancing guest experiences with digital marketing that brings your hospitality brand to life and drives lasting connections.",
    logo: lone,
    alt: "lone stars cent",
    link: "https://lonestarscent.com/",
  },
  Information_Technology: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Management_Consultancy: {
    heading: "Management Consultancy",
    des: "Providing strategic marketing insights to help consultancy firms gain a competitive edge and grow their client base.",
    logo: psyethos,
    alt: "psyethos",
    link: "",
  },
  Retail: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
  Construction: {
    heading: "Construction",
    des: "Building strong foundations with creative, impactful marketing for your construction business.",
    logo: tanks,
    alt: "tanks",
    link: "https://www.tanksandcovers.com/",
  },
  Ecommerce: {
    heading: "Ecommerce",
    des: "Boosting sales and optimizing online presence with expert digital marketing strategies tailored for e-commerce success.",
    logo: softside,
    alt: "softside",
    link: "https://www.softsidebyhunt.com/",
  },
  Oil_and_Energy: {
    heading: "Oil and Energy",
    des: "Fueling success with tailored marketing strategies that drive growth, innovation, and brand strength in the oil and energy sectors.",
    logo: pero,
    alt: "pero",
    link: "https://powersquared.tech/",
  },
  Real_Estate: {
    heading: "",
    des: "",
    logo: "",
    alt: "",
    link: "",
  },
};

const Expertise: React.FC = () => {
  const [selectedIndustry, setSelectedIndustry] =
    useState<string>("Automative");

  const handleIndustryClick = (industry: string) => {
    if (experties[industry].heading && experties[industry].des) {
      setSelectedIndustry(industry);
    }
  };

  return (
    <section>
      <div className="mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row gap-6 justify-between z-10 relative">
          {/* Industries List */}
          <div className="w-full lg:w-[50%] ">
            <h2 className="font-montserrat font-bold text-6xl mb-6">
              Expertise
            </h2>
            <h2 className="text-lg font-semibold mb-4">Industries</h2>
            <ul className=" grid grid-cols-3 space-y-2">
              {Object.keys(experties).map((industry, index) => {
                const isAvailable =
                  experties[industry].heading && experties[industry].des;
                return (
                  <div className="flex " key={index}>
                    <div
                      className={`p-[1px] hover:custom-gradient2 rounded-full self-start ${
                        selectedIndustry === industry ? "custom-gradient2" : ""
                      }`}
                    >
                      <li key={industry}>
                        <button
                          onClick={() => handleIndustryClick(industry)}
                          className={`w-full text-left text-sm font-montserrat font-semibold bg-white py-2 px-4 rounded-full transition ${
                            selectedIndustry === industry
                              ? ""
                              : isAvailable
                              ? "hover:bg-gray-100"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={!isAvailable}
                        >
                          {industry.replace(/_/g, " ")}{" "}
                          {/* Replace underscores with spaces */}
                        </button>
                      </li>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>

          {/* Purple Content Box */}
          <div className="w-full lg:w-[50%] bg-purple-600 text-white px-4 sm:px-12 py-10 rounded-lg shadow-lg">
            {experties[selectedIndustry].heading ? (
              <div className="flex flex-col justify-between">
                <h3 className="text-xl font-semibold mb-4">
                  {experties[selectedIndustry].heading}
                </h3>
                <p className="mt-4 font-gtAmerica text-xl sm:text-3xl font-medium mb-4">
                  {experties[selectedIndustry].des}
                </p>
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center items-start">
                  <a href={experties[selectedIndustry].link} className=" cursor-pointer mb-4 sm:mb-0" target="_blank">
                    <img src={experties[selectedIndustry].logo} alt={experties[selectedIndustry].alt} className="hover:scale-110 transition-all duration-500"/>
                  </a>
                  <Link className="" to="/contact">
                    <div className="p-[2px] custom-gradient2 rounded-3xl items-start">
                      <button className="bg-white text-purple-600 py-2 px-6 rounded-3xl hover:bg-purple-100 transition">
                        Let’s talk
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <p>No information available for this industry.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
